export const colorData = {
  white: "#FFFFFF",
  fontWhite: "#ffffff",
  laseWhite: "#F5F2E9",
  laseBlack: "#757A73",
  blue: "#1636D6",
  darkBlue: "#0C218C",
  darkBlue1: "#1D2561",
  blue0: "#1D77CC",
  rose: "#F10D4A",
  laseOrenge: "#FCE2B3",
  vilet: "#DC4CDC",
  lightSky: "#8DFFDD",
  skyBlue: "#1DF3FA",
  green: "#08E70F",
  green1: "#51d121",
  darkgreen: "#0DA912",
  darkOrange: "#EC6639",
  primary: "#00b6be",
  primary2: "#00888a",
  green3: "#BFE3E2",
};
export const selectedColor = {
  Orange: "#fb8500",
  darkblue: "#023047",
  yellow: "#ffb703",
  lightblue: "#00b4d8",
  lightblue1: "#009EFB",
  lightWhite: "#eaeaea",
  lightgreen: "#2b2d42",
  blue: "#1d4e89",
  blue1: "#0077b6",
};
export let COLORS = {
  primary: "#00b6be",
  primary2: "#00888a",
  primaryRed: "#ED1A3B",
  primaryBlue: "#162B75",
  primaryGrey: "#606161",
  secondary: "#C4C4C4",
  secondary1: "#eee",
  blue: "#1E39A1",
  green: "#6acccb",
  green1: "#BFE3E2",
  rose: "#FE6565",
  white: "white",
  grey: "grey",
  black: "black",
  yellow: "#FFBB38",
  skyblue: "#96e1ff",
  lightred: "#faa7a1",
  red: "red",
  violet: "#bcbff2",
  transparent: "transparent",
  textinputback: "#F6F6F6",
  greens: {
    green1: "#4FB0AB",
    C008A8A: "#008A8A",
    C199992: "#199992",
    CD5ECEB: "#D5ECEB",
  },
  blues: {
    C1D2561: "#1D2561",
    C252525: "#252525",
    C424242: "#424242",
    C1E39A1: "#1E39A1",
  },
  greys: {
    CC4C4C4: "#979797",
    CECEEF2: "#ECEEF2",
    C747474: "#747474",
    C414042: "#414042",
    CECEFF1: "#ECEFF1",
    C424242: "#424242",
  },
  home: { box: { CFFDCDC: "#FFDCDC", CDBE2FF: "#DBE2FF", CFFF0D3: "#FFF0D3" } },
  C008A8A: "#008A8A",
  C199992: "#199992",
  C1D2561: "#1D2561",
  C1E39A1: "#1E39A1",
  C252525: "#252525",
  C414042: "#414042",
  C424242: "#424242",
  C4FB0AB: "#4FB0AB",
  CC4C4C4: "#C4C4C4",
  C747474: "#747474",
  C979797: "#979797",
  CBFE3E2: "#BFE3E2",
  CD5ECEB: "#D5ECEB",
  CDBE2FF: "#DBE2FF",
  CECEEF2: "#ECEEF2",
  CECECEC: "#ECECEC",
  CFFDCDC: "#FFDCDC",
  CFFF0D3: "#FFF0D3",
  CFFF9ED: "#FFF9ED",
  CFFBB38: "#FFBB38",
  CFF961B: "#FF961B",
  CFF5454: "#FF5454",
  CE31414: "#E31414",
  CFF0000: "#FF0000",
  CED4343: "#ED4343",
  C646161: "#646161",
  CF6F6F6: "#F6F6F6",
  CF7F7FC: "#F7F7FC",
  C11CF00: "#11CF00",
  CE5E5E5: "#E5E5E5",
  CEFEFFA: "#EFEFFA",
  F7630F: "#F7630F",
  greenNew: "#01B53E",
  Cf4ba49: "#DF980F",
  CCFFFAD: "#E1FCCD",
  C5AC210: "#5AC210",
  C1EBCDC: "#1EBCDC",
  gold: ["#F39C12", "#D68910"],
  silver: ["#AAB7B8", "#95A5A6"],

  subscription: [
    ["#3598dc", "white", "white"],
    ["#AAB7B8", "white", "white"],
    ["#F39C12", "white", "white"],
    ["#e7505a", "white", "white"],
    ["#e7505a", "white", "white"],
    ["#e7505a", "white", "white"],
    ["#e7505a", "white", "white"],
    ["#e7505a", "white", "white"],
    ["#e7505a", "white", "white"],
    ["#e7505a", "white", "white"],
    ["#e7505a", "white", "white"],
    ["#e7505a", "white", "white"],
    ["#e7505a", "white", "white"],
    ["#e7505a", "white", "white"],
    ["#e7505a", "white", "white"],
  ],
  silverIcon: "#E5E7E9",
  bronzeIcon: "#641E16",
  otherIcon: "#BB8FCE",
};
export default COLORS;
export const fontData = {
  vsmall: "8px",
  small: "9px",
  small0: "10px",
  small1: "11px",
  small2: "12px",
  small3: "13px",
  small4: "14px",
  small5: "16px",
  mediam: "18px",
  mediam0: "20px",
  mediam1: "22px",
  mediam2: "24px",
};
export const fontDataStyle = {};
export const showStyle = {
  pointer: "pointer",
  overx: "auto",
  overY: "auto",
  maxHeightsmallvs: 390,
  maxHeightsmall: 430,
  maxHeightsmall1: 450,
  maxHeight: 500,
  maxHeight0: 670,
  maxHeight1: 700,
  maxHeight2: 800,
  maxHeight3: 900,
};
export const btnsty = {
  flex: 1,
  padding: "7px",
  textTransform: "capitalize",
  fontSize: "12px",
  fontWeight: "500",
  margin: "0 5px",
  borderRadius: "10px",
  // ":hover": {
  //   color: "white",
  // },
};
export const liststy = {
  backgroundColor0: "#0eabf7",
  backgroundColor1: "#DDDDDD",
  backgroundColor2: "#85F4FF",
  backgroundColor3: "#968C83",
  backgroundColor4: "#F6F6F6",
  backgroundColor5: "#FFD5CD",
  backgroundColor6: "#FAF3F3",
  backgroundColor7: "#E4FBFF",
  backgroundColor8: "#D7FFFD",
  backgroundColor9: "#E1F2FB",
  backgroundColor10: "#FCF5EE",
  backgroundColorgreen: "#40a829",
  backgroundColorblue: "#39B5E0",
};
export const consultationStatus = {
  scheduled: "#01B53E",
  completed: "#606161",
  review: "#162B75",
  cancelled: "#E31414",
  rescheduled: "#FFBB38",
  missed: "#f3ab4b",
  default: "#008A8A",
};
export const profilebackColor = {
  white: "#ffff",
  whiteout: "#ffff",
  whiteorder: "#ffff",
  whitepayment: "#FFFF",
  blue: "#1f287e",
  blueicon: "#7181da",
  bluebookingId: "#1f287e",
  orderbookingId: "#1f287e",
  orderViewIcon: "#1f287e",
  orderDateColor: "#d8ecea",
  orderStatusColor: "#d8ecea",
  orderStatusinfontColor: "#1f287e",
  orderviewbtn: "#1f287e",
  orderbutontrack: "#f7043a",
  paymentDownload: "#37b24d",
  paymentItemTextColor: "#2d8e83",
  reportdownloadcolor: "#5dafab",
  popupcolor: "#4FB0AB",
  reportbackground: "#d8ecea",
};
export const profilepagefont = {
  orderstatusfont: "12px",
};
export const buttonHover = (bcol, col) => {
  return {
    backgroundColor: bcol,
    color: col,
    ":hover": {
      backgroundColor: bcol,
    },
  };
};
export const buttonHover2 = (bcol1, bcol2, col1, col2) => {
  return {
    backgroundColor: bcol1,
    color: col1,
    ":hover": {
      backgroundColor: bcol2,
      color: col2,
    },
  };
};
