import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getFollowUpSelectedDoctor,
  getPackageList,
  triggerToastError,
} from "../services/service";
import SelectAddress from "./SelectAddress";
import SelectPatient from "./SelectPatient";

const SelectPatientAndAddress = (props) => {
  const { bookingtype, bannerCheck } = useSelector(
    (state) => state.globalReducer
  );
  console.log(bookingtype);
  const dispatch = useDispatch();
  const nav = useNavigate();
  useEffect(() => {
    dispatch({ type: "CONSULTATION_STEP", payload: 0 });
  }, []);
  const { selectedAddress, selectedUser, bookDoctor } = useSelector(
    (state) => state.usersReducer
  );
  const [selectPatientAndAddressTab, setSelectPatientAndAddressTab] =
    useState(0);
  const handleClose = () => {
    setSelectPatientAndAddressTab(0);
    props.setShowModel(false);
  };
  return (
    <Dialog open={props.showModel} fullWidth maxWidth="sm">
      <DialogTitle sx={{ fontSize: "17px" }}>
        Select Member & Address
      </DialogTitle>
      <DialogContent dividers>
        {
          {
            0: <SelectPatient props={props} />,
            1: <SelectAddress props={props} />,
          }[selectPatientAndAddressTab]
        }
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            backgroundColor: "#FF033E",
            textTransform: "capitalize",
            color: "white",
            ":hover": {
              backgroundColor: "#EF0107",
              // color: "white",
            },
          }}
          variant="contained"
          onClick={handleClose}
        >
          Close
        </Button>
        {selectPatientAndAddressTab === 0 ? (
          <Button
            color="info"
            variant="contained"
            sx={{
              backgroundColor: "#1cacf1",
              textTransform: "capitalize",
              color: "white",
              ":hover": {
                backgroundColor: "#0396dd",
                // color: "white",
              },
            }}
            onClick={() => setSelectPatientAndAddressTab(1)}
          >
            Next
          </Button>
        ) : (
          <>
            <Button
              color="info"
              variant="contained"
              sx={{
                backgroundColor: "#1cacf1",
                textTransform: "capitalize",
                color: "white",
                ":hover": {
                  backgroundColor: "#0396dd",
                  // color: "white",
                },
              }}
              onClick={() => setSelectPatientAndAddressTab(0)}
            >
              Back
            </Button>
            <Button
              color="info"
              sx={{
                backgroundColor: "#1cacf1",
                textTransform: "capitalize",
                color: "white",
                ":hover": {
                  backgroundColor: "#0396dd",
                  // color: "white",
                },
              }}
              variant="contained"
              disabled={
                (selectedAddress &&
                  Object.keys(selectedAddress).length === 0) ||
                (selectedUser && Object.keys(selectedUser).length === 0)
              }
              onClick={() => {
                if (bookingtype === "Order Medicine") {
                  dispatch({ type: "PHARMACY_OPTIONS", payload: 0 });
                }
                if (bookingtype === "Order Test") {
                  dispatch({ type: "LAB_OPTIONS", payload: 0 });
                }
                if (bookingtype === "Book Appointment") {
                  dispatch({ type: "SELECTED_CONSULTATION", payload: {} });
                  dispatch({ type: "CONSULTATION_STEP", payload: 0 });

                  // const data = {
                  //   itemsPerPage: 1,
                  //   pageNumber: 1,
                  //   searchCriteria: {
                  //     empId: bookDoctor.DoctorId,
                  //   },
                  // };
                  // dispatch(getFollowUpSelectedDoctor(data, nav));
                }
                if (bookingtype === "Book Package") {
                  dispatch({ type: "LAB_OPTIONS", payload: 8 });
                }
                if (bookingtype === "Test Banner") {
                  if (bannerCheck?.available && bannerCheck.type === "tests") {
                    // nav("/lab");
                    dispatch({ type: "LAB_OPTIONS", payload: 2 });
                  } else {
                    triggerToastError(
                      "Sorry, the test selected is not available for this PINCODE."
                    );
                  }
                }
                if (bookingtype === "Package Banner") {
                  if (
                    bannerCheck?.available &&
                    bannerCheck.type === "packages"
                  ) {
                    // nav("/lab");
                    dispatch({ type: "LAB_OPTIONS", payload: 1 });
                  } else {
                    triggerToastError(
                      "Sorry, the package selected is not available for this PINCODE."
                    );
                  }
                }
                if (bookingtype === "Book") {
                  dispatch({ type: "SELECTED_CONSULTATION", payload: {} });
                  const data = {
                    itemsPerPage: 1,
                    pageNumber: 1,
                    searchCriteria: {
                      empId: bookDoctor.DoctorId,
                    },
                  };
                  dispatch(getFollowUpSelectedDoctor(data, nav));
                }

                nav(
                  bookingtype === "Book Appointment"
                    ? "/consultation"
                    : bookingtype === "Order Test" ||
                      (bookingtype === "Package Banner" &&
                        bannerCheck?.available &&
                        bannerCheck.type === "packages") ||
                      (bookingtype === "Test Banner" &&
                        bannerCheck?.available &&
                        bannerCheck.type === "tests") ||
                      bookingtype === "Book Package"
                    ? "/lab"
                    : bookingtype === "Order Medicine"
                    ? "/pharmacy"
                    : null
                );

                console.log(bookingtype);
              }}
            >
              Continue
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SelectPatientAndAddress;
