import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState, useEffect } from "react";
// import DeleteIcon from "@mui/icons-material/Delete";
import GeneralComponent from "./GeneralComponent";
import { useDispatch } from "react-redux";
import DownloadingIcon from "@mui/icons-material/Downloading";
import { fileAttachmentDownloadURLGen } from "../../services/service";
import image from "../../assets/images/logos/Diahome Logo DP.png";

const ReceivedMsgComponent = ({
  message,
  receivedDeletedMsgId,
  setReceivedDeletedMsgId,
}) => {
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");
  const [item, setItem] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const getImgUrl = async (fileName) => {
    const signedUrl = await dispatch(fileAttachmentDownloadURLGen(fileName));
    setUrl(signedUrl);
  };
  useEffect(() => {
    if (message) setItem(message);
    if (
      message?.message?.event?.toLowerCase() === "file" &&
      message?.message?.fileType.includes("image")
    ) {
      getImgUrl(message?.message?.text);
    } else {
      setUrl("");
    }
  }, [message]);
  const deleteMsg = () => {
    const t = message;
    t.isActive = false;
    setItem(t);
    setIsDeleted(true);
  };
  useEffect(() => {
    if (receivedDeletedMsgId && receivedDeletedMsgId === message.id) {
      deleteMsg();
      setReceivedDeletedMsgId(null);
    }
  }, [receivedDeletedMsgId]);
  const downloadFileWithFileName = async (fileName) => {
    try {
      const signedUrl = await dispatch(fileAttachmentDownloadURLGen(fileName));
      window.open(signedUrl);
    } catch (error) {}
  };
  return (
    <Box>
      {item?.isActive === false ? (
        <Card
          key={item?.id}
          sx={{
            bgcolor: (theme) => theme.palette.common.white,
            borderRadius: "10px",
            width: "500px",
          }}
        >
          <CardHeader
            titleTypographyProps={{ variant: "caption" }}
            sx={{
              p: "8px 16px",
              paddingBottom: "8px !important",
            }}
            title={item?.message?.senderName}
            subheaderTypographyProps={{ variant: "caption" }}
            subheader={moment(item?.message?.time).format("hh:mm A")}
          />

          <CardContent
            sx={{
              p: "8px 16px",
              paddingBottom: "8px !important",
            }}
          >
            <GeneralComponent />
          </CardContent>
        </Card>
      ) : (
        <Card
          key={item?.id}
          sx={{
            bgcolor: (theme) => theme.palette.common.white,
            borderRadius: "10px",
            width: "500px",
          }}
        >
          {item?.message?.event === "alert" ? null : (
            <CardHeader
              titleTypographyProps={{ variant: "caption" }}
              sx={{
                p: "8px 16px",
                paddingBottom: "8px !important",
              }}
              action={
                <>
                  {item?.isActive === false ? null : (
                    <>
                      {item?.message?.event?.toLowerCase() === "file" ? (
                        <IconButton
                          aria-label="settings"
                          // onClick={() => onDelete(val.id)}
                          onClick={() =>
                            downloadFileWithFileName(item?.message?.text)
                          }
                        >
                          <DownloadingIcon color="primary" fontSize="small" />
                        </IconButton>
                      ) : null}
                    </>
                  )}
                </>
              }
              title={item?.message?.senderName}
              subheaderTypographyProps={{ variant: "caption" }}
              subheader={moment(item?.message?.time).format("hh:mm A")}
            />
          )}
          {item?.message?.event?.toLowerCase() === "file" ? (
            item?.message?.fileType.includes("image") ? (
              <>
                <CardMedia
                  component="img"
                  height="140"
                  image={url}
                  alt="green iguana"
                />
                <Typography
                  variant="overline"
                  display="block"
                  gutterBottom
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {item?.message?.category?.name}
                </Typography>
              </>
            ) : (
              <>
                <CardMedia
                  component="img"
                  height="140"
                  image={image}
                  alt="green iguana"
                />
                <Typography
                  variant="overline"
                  display="block"
                  gutterBottom
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {item?.message?.category?.name}
                </Typography>
              </>
            )
          ) : (
            <CardContent
              sx={{
                p: "8px 16px",
                paddingBottom: "8px !important",
              }}
            >
              {item?.message?.text}
            </CardContent>
          )}
        </Card>
      )}
    </Box>
  );
};

export default ReceivedMsgComponent;
