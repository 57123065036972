import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
  compose,
} from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createBrowserHistory } from "history";
import { routerMiddleware, connectRouter } from "connected-react-router";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { encryptTransform } from "redux-persist-transform-encrypt";
import loginReducer from "./reducers/loginReducer";
import globalReducer from "./reducers/globalReducer";
import usersReducer from "./reducers/usersReducer";
import consultationReducer from "./reducers/consultationReducer";
import pharmacyReducer from "./reducers/pharmacyReducer";
import labReducer from "./reducers/labReducer";
const persistConfig = {
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "diahome-secret-key",
      onError: function (error) {
        // Handle the error.
        console.log(error);
      },
    }),
  ],
};

let middleware = [];
middleware = [...middleware, thunk];

export const history = createBrowserHistory();
const reducer = combineReducers({
  router: connectRouter(history),
  loginReducer,
  globalReducer,
  usersReducer,
  consultationReducer,
  pharmacyReducer,
  labReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
  persistedReducer,
  compose(
    composeWithDevTools(
      applyMiddleware(...middleware, routerMiddleware(history))
    )
  )
);

// export default store;
export const persistor = persistStore(store);
