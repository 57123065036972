import { createTheme } from "@mui/material/styles";
// import { orange } from "@mui/material/colors";

export const customTheme = createTheme({
  palette: {
    primary: {
      light: "#00b6be80",
      main: "#00b6be",
      dark: "#00888a",
    },
    secondary: {
      main: "#C4C4C4",
      dark: "#EEE",
    },
    error: {
      main: "#ED1A3B",
    },
    info: {
      main: "#162B75",
      light: "#162b7596",
    },
    // success: {
    //   light: "#BFE3E2",
    //   main: "#6acccb",
    // },
  },
});
