import { errormsg } from "./service";
import axiosInterceptors from "./interceptorAxios";

// const baseURL = 'https://api.diahome.com' + "/diahome/v1/api/patient-service";
const baseURL = process.env.REACT_APP_BASE_URL + "/diahome/v1/api/patient-service";
export const sendOtpOnAccountDeletion = async(d) => {
    try {
      const data = {
        mobileNumber: d,
      };
      const config = {
        method: "post",
        url: `${baseURL}/profiles/login/otp`,
        data: data,
      };
  
      return await axiosInterceptors(config)
        .then((response) => response)
        .catch(function (error) {
          errormsg(error);
        });
    } catch (error) {
    }
  };

  export const verifyOtpOnAccountDeletion = async (patientId,otp) =>{
    const data = {
      patientId,
      otp
    };
    const config = {
      method: "post",
      url: `${baseURL}/profiles/login/otp/verify?type=login`,
      data: data,
    };
    return await axiosInterceptors(config)
    .then((response) => response)
    .catch(function (error) {
      errormsg(error);
    });
}

  export const removeAccountOnAccountDeletion = async (patientId, token, remarks) =>{
    const data = {
      patientId,
      backupRequest: true,
      remarks: remarks,
      status: "User Request"
    };
    const config = {
      method: "post",
      url: `${baseURL}/profiles/delete/request/raise`,
      data: data,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };
    return await axiosInterceptors(config)
    .then((response) => response)
    .catch(function (error) {
      errormsg(error);
    });
}