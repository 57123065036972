import * as React from "react";
// import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
// import CardHeader from "@mui/material/CardHeader";
// import CardMedia from "@mui/material/CardMedia";
// import CardContent from "@mui/material/CardContent";
// import CardActions from "@mui/material/CardActions";
// import Collapse from "@mui/material/Collapse";
// import Avatar from "@mui/material/Avatar";
// import IconButton from "@mui/material/IconButton";
// import { red } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
// import { useDispatch } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
export default function IncomingMessage({ data, onDecline, dispatch }) {
  return (
    <Card
      sx={{
        height: 100,
        background:
          "linear-gradient(84deg, rgba(87,230,111,1) 0%, rgba(79,216,241,1) 100%)",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 700, color: "#e34530" }}
          component={"b"}
        >
          Message Notification
        </Typography>
        <CloseIcon
          onClick={onDecline}
          size="small"
          sx={{
            cursor: "pointer",
            ":hover": {
              color: "#f01f43",
              zoom: "10px",
            },
          }}
        />
      </Box>
      <div style={{ display: "flex", paddingLeft: 20 }}>
        {data.data.redirect === "customerSupportChat" ? (
          <Typography
            // onClick={() => {
            //   if (data.data.redirect === "customerSupportChat") {
            //     dispatch({
            //       type: "SHOW_SUPPORT",
            //       payload: true,
            //     });
            //     dispatch({ type: "PROFILE_MENU_TAB", payload: 10 });
            //     dispatch({
            //       type: "SHOW_CHAT",
            //       payload: true,
            //     });
            //   }
            // }}
            variant="body2"
            component={"b"}
            sx={{
              textDecoration: "none",
              maxBlockSize: "50px",
              overflowY: "auto",
              fontWeight: 600,
              p: 0,
              m: 0,
            }}
          >
            {" "}
            {/* <a
              href="/profile"
              style={{ textDecoration: "none", color: "black" }}
            > */}
            {data?.notification?.body} {/* </a> */}
          </Typography>
        ) : (
          <Typography variant="body2" component={"b"}>
            {" "}
            {data?.notification?.body}
          </Typography>
        )}
      </div>
    </Card>
  );
}
