const initialState = {
  consultationType: "",
  consultationSpecilityDoctors: [],
  consultationStep: 0,
  consultationSelectedDoctor: {},
  bookingAppointmentStep: 0,
  consultationSlots: [],
  consultationOrderDetails: {},
  consultationCouponCode: {},
  consultationAvailabilityCheck: {},
  consultationOrderRazerpayDetails: {},
  selectedConsultation: {},
  upcomingConsultation: [],
  vitalData: {},
  followUp: [],
  consultationSuccessPopup: {},
  userConsulation: {},
};
const consultationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_REFERAL_DATA":
      return {
        ...state,
        referalData: action.payload,
      };
    case "GET_FOLLOWUP_DATA":
      return {
        ...state,
        followUp: action.payload,
      };
    case "MYCONSULTATION":
      return {
        ...state,
        userConsulation: action.payload,
      };
    case "CONSULTATION_SUCCESS_POPUP":
      return {
        ...state,
        consultationSuccessPopup: action.payload,
      };
    case "VITAL_DATA":
      return {
        ...state,
        vitalData: action.payload,
      };
    case "CONSULTATION_TYPE":
      return {
        ...state,
        consultationType: action.payload,
      };
    case "CONSULTATION_SPECILITY_DOCTORS":
      return {
        ...state,
        consultationSpecilityDoctors: action.payload,
      };
    case "CONSULTATION_STEP":
      return {
        ...state,
        consultationStep: action.payload,
      };
    case "CONSULTATION_SELECTED_DOCTOR":
      return {
        ...state,
        consultationSelectedDoctor: action.payload,
      };
    case "BOOKAPPOINTMENT_STEP":
      return {
        ...state,
        bookingAppointmentStep: action.payload,
      };
    case "CONSULTATION_SLOTS":
      return {
        ...state,
        consultationSlots: action.payload,
      };
    case "CONSULTATION_ORDER_DETAILS":
      return {
        ...state,
        consultationOrderDetails: action.payload,
      };
    case "CONSULTATION_COUPONCODE":
      return {
        ...state,
        consultationCouponCode: action.payload,
      };
    case "CONSULTATION_AVAILABILITY_CHECK":
      return {
        ...state,
        consultationAvailabilityCheck: action.payload,
      };
    case "CONSULTATION_ORDER_RAZERPAY_DETAILS":
      return {
        ...state,
        consultationOrderRazerpayDetails: action.payload,
      };
    case "SELECTED_CONSULTATION":
      return {
        ...state,
        selectedConsultation: action.payload,
      };
    case "UPCOMING_CONSULTATION":
      return {
        ...state,
        upcomingConsultation: action.payload,
      };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
export default consultationReducer;
