import React, { Suspense, lazy, useEffect, useState } from "react";
import "./App.css";
import { v4 as uuidv4 } from "uuid";
import LandingPage from "./pages/landingPage/LandingPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { history, store } from "./store/store";
import AgoraRTC from "agora-rtc-sdk";
import AgoraRTM from "agora-rtm-sdk";
import NotFound from "./pages/notFound/NotFound";
import PrivateRoute from "./pages/auth/PrivateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Consultation from "./pages/consultation/Consultation";
import ConsultationBooking from "./pages/consultation/consultationBooking/ConsultationBooking";
import VideoAudioChat from "./pages/videoAudioChat/VideoAudioChat";
// import { getTokenFireBase, messaging } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import { useTransition, animated } from "react-spring";
import {
  ChatService,
  getAgoraRTMToken,
  getNotificationCountService,
  getPatientAddress,
  getPatientPopup,
  // getUnreadedMessageService,
  refreshFirebaseTokenService,
  sendPushNotificationService,
} from "./services/service";
import Draggable from "react-draggable";
import VideoAudioCall from "./pages/videoAudioChat/VideoAudioCall";
import callRingTone from "./assets/audio/incomingCall.mp3";
import msgRingTone from "./assets/audio/msg.mp3";
import Pharmacy from "./pages/pharmacy/Pharmacy";
import Lab from "./pages/lab/Lab";
import UnderMaintance from "./pages/undermaintance/UnderMaintance";
import IncomingCallPopup from "./components/call/incomingCallPopUp";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
// import { AccountCircle } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
// import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { notificationType } from "./utils/utils";
import { onMessage } from "firebase/messaging";
import { Avatar } from "@mui/material";
import { red } from "@mui/material/colors";
import ReminderPopup from "./pages/remindermodal/ReminderPopup";
import IncomingMessage from "./components/message/IncomingMessage";
import FilePreview from "./components/showPdfandImage/FilePreview";
import AccountDeletionPage from "./pages/accountDeletion/accountDeletionRequestPage";

const Header = lazy(() => import("./pages/header/Header"));
// const Footer = lazy(() => import("./pages/footer/Footer"));
const Login = lazy(() => import("./pages/login/Login"));
const Profile = lazy(() => import("./pages/profile/Profile"));
// const NotFound = lazy(() => import("./pages/notFound/NotFound"));
export const client = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});
export const ThemeContext = React.createContext();
// const renderLoader = () => <p>Loading</p>;
const App = () => {
  var showed = true;
  const dispatch = useDispatch();

  const [isTokenFound, setTokenFound] = useState(false);
  const [fireBaseTokenValue, setFirebaseTokenValue] = useState(null);
  // const [show, setShow] = useState(false);
  const [notification, setNotification] = useState(null);
  const [audio] = useState(new Audio(callRingTone));
  const [msgTone] = useState(new Audio(msgRingTone));
  const [channel, setChannel] = useState(null);
  const [isSend, setIsSend] = useState(null);
  const [inComingMassage, setInComingMassage] = useState(null);
  // const [fileOpen, setFileOpen] = useState(true);
  const [selectedCallView, setSelectedCallView] = useState(0);
  const [incomingCall, setIncomingCall] = useState(null);
  const [startCall, setStartCall] = useState(false);

  const startCallFun = (data) => {
    setNotification(data);
    setStartCall(true);
  };
  const [data, setData] = useState({
    clientRTM: null,
    rtmToken: null,
    setChannel: setChannel,
    isSend: null,
    supportChannel: null,
    startCallFun: startCallFun,
  });
  const { userLogin } = useSelector((state) => state.loginReducer);
  const { showChat, selectedProfileMenuTab } = useSelector(
    (state) => state.usersReducer
  );
  const { patientReminder, filePriview } = useSelector(
    (state) => state.globalReducer
  );
  const { notificationCount } = useSelector((state) => state.globalReducer);

  console.log(userLogin, "userLoginuserLoginuserLogin");
  const closePriview = () => {
    dispatch({
      type: "FILE_PRIVIEW",
      payload: {
        fileOpen: false,
        fileType: null,
        signedUrl: null,
      },
    });
  };
  const { primaryUser, selectedUser } = useSelector(
    (state) => state.usersReducer
  );
  const setFirebaseToken = (token) => {
    console.log(token, "token");
    setFirebaseTokenValue(token);
  };
  console.log(isTokenFound);
  const initAgora = async (patientId) => {
    console.log(patientId, "patientId");
    const token = await getAgoraRTMToken(patientId);
    console.log(token, "token");
    if (token) {
      const client = await AgoraRTM.createInstance(
        "2a152b3f32f846aeb15cfb04cc995aa5"
      );
      // setClient(client);
      if (client)
        client.on("MessageFromPeer", function (message, peerId) {
          console.log(message, peerId);
        });

      await client
        .login({
          uid: patientId,
          token: token,
        })
        .then((val) => {
          console.log(val, patientId, token);
        })
        .catch((err) => {
          console.log(err, patientId, token);
        });
      !data.clientRTM &&
        setData({
          ...data,
          clientRTM: client,
          rtmToken: token,
        });
    }
  };

  const broadcast = new BroadcastChannel("count-channel");
  const showPopups = (x) => {
    const tdyConsultationPopUp = () => {
      if (x["TodayConsultation"].length > 0) {
        showed = true;
        dispatch({
          type: "GET_PATIENT_POPUP",
          payload: {
            active: true,
            data: x["TodayConsultation"][0],
            type: "TodayConsultation",
          },
        });
      }
    };
    const rescheduleConsultationPopUp = () => {
      if (x["RescheduleConsultation"].length > 0) {
        showed = true;
        dispatch({
          type: "GET_PATIENT_POPUP",
          payload: {
            active: true,
            data: x["RescheduleConsultation"][0],
            type: "RescheduleConsultation",
          },
        });
      }
    };
    const followUpPopUp = () => {
      if (x["followUp"].length > 0) {
        showed = true;
        dispatch({
          type: "GET_PATIENT_POPUP",
          payload: { active: true, data: x["followUp"][0], type: "followUp" },
        });
      }
    };
    const referralPopUp = () => {
      if (x["referral"].length > 0) {
        showed = true;
        dispatch({
          type: "GET_PATIENT_POPUP",
          payload: {
            active: true,
            data: x["referral"][0],
            type: "referral",
          },
        });
      }
    };
    // const unconsultedSpecialitiesPopUp = () => {
    //   if (x["unconsultedSpecialities"].length > 0) {
    //     showed = true;
    //     dispatch({
    //       type: "GET_PATIENT_POPUP",
    //       payload: {
    //         active: true,
    //         type: {
    //           data: x["unconsultedSpecialities"],
    //           type: "unconsultedSpecialities",
    //         },
    //       },
    //     });
    //   }
    // };
    tdyConsultationPopUp();
    setTimeout(rescheduleConsultationPopUp, 15000);
    setTimeout(followUpPopUp, 15000 * 2);
    setTimeout(referralPopUp, 15000 * 3);
    // referralPopUp();
    // followUpPopUp();
    // setTimeout(unconsultedSpecialitiesPopUp,POPUPDURATION * 4 );
  };
  const GetApiByMainProfile = () => {
    if (userLogin) {
      const popup = {
        patientId: selectedUser.patientId,
      };
      dispatch(getPatientPopup(popup))
        .then(async (res) => {
          let x = {};
          // eslint-disable-next-line array-callback-return
          await res.map((item) => {
            if (item.hasOwnProperty("TodayConsultation"))
              x.TodayConsultation = item?.TodayConsultation || [];
            else if (item.hasOwnProperty("RescheduleConsultation"))
              x.RescheduleConsultation = item?.RescheduleConsultation || [];
            else if (item.hasOwnProperty("followUp"))
              x.followUp = item?.followUp || [];
            else if (item.hasOwnProperty("unconsultedSpecialities"))
              x.unconsultedSpecialities = item?.unconsultedSpecialities || [];
            else if (item.hasOwnProperty("referralDetails"))
              x.referral = item?.referralDetails || [];
          });

          // setpopups(x);
          showPopups(x);
          console.log(x, "popups");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    GetApiByMainProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   getTokenFireBase(setTokenFound, setFirebaseToken);
  //   console.log("gettoken func calledi useef");
  //   if (userLogin) {
  //     // dispatch(getUnreadedMessageService(1, 5));
  //     // init();

  //     dispatch(getNotificationCountService());
  //     console.log(notificationCount);
  //   }
  //   // onMessageListener().then((payload) => {
  //   //   console.log(payload, "onMessageListener");
  //   //   dispatch(getNotificationCountService());
  //   //   handleNotification(payload, userLogin);
  //   //   // dispatch(getUnreadedMessageService(1, 5));
  //   //   // console.log(payload, 'payload');
  //   // });
  //   onMessage(messaging, (payload) => {
  //     console.log(payload, "onMessageListener");
  //     dispatch(getNotificationCountService());
  //     console.log(notificationCount);
  //     handleNotification(payload, userLogin);
  //     // ...
  //   });
  //   broadcast.onmessage = (event) => {
  //     // const token = cookies.get("token");
  //     // const isLog = store.getState().CareTakerDataReducer[stateNames.userProfile]
  //     console.log(event?.data, "from background listener");
  //     if (
  //       event?.data?.type?.data?.event === notificationType.type1 ||
  //       event?.data?.type?.data?.event === notificationType.type2 ||
  //       event?.data?.type?.data?.event === notificationType.type4 ||
  //       event?.data?.type?.data?.event === notificationType.type5
  //     ) {
  //       handleNotification(event?.data?.type, userLogin);
  //     }
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  useEffect(() => {
    if (userLogin && primaryUser && Object.keys(primaryUser).length !== 0) {
      // dispatch(getUnreadedMessageService(1, 5));
      console.log(
        "dshviushdvhiosdhvohsodihvoishdvoihsoidhvoihsoidvh",
        primaryUser
      );
      initAgora(primaryUser.patientId);
    } else if (!userLogin && data.clientRTM) {
      data.clientRTM.logout();
      console.log("user logout complete 1887");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryUser, userLogin]);
  const log = store.getState();
  console.log(log, "store.getState(");
  const handleNotification = (payload, isLog) => {
    const log = store.getState();
    console.log(log.loginReducer.userLogin, "log.loginReducer.userLogin");
    if (log.loginReducer.userLogin) {
      console.log(payload);
      if (payload.data.recipient === log.loginReducer.patientId) {
        console.log(payload.data.recipient === log.loginReducer.patientId);
        console.log(log.loginReducer.userLogin);

        if (payload?.data?.event?.toLowerCase() === "call") {
          setIncomingCall({
            data: payload?.data,
            title: payload?.notification?.title,
            body: payload?.notification?.body,
            image: payload?.notification?.image,
          });
        } else if (
          payload?.data?.event?.toLowerCase() === notificationType.type5
        ) {
          setIncomingCall((prev) => {
            if (payload?.data?.callId === prev?.data?.callId) return null;
            return prev;
          });
        }
        setNotification((prev) => {
          if (payload?.data?.callId === prev?.data?.callId) {
            setStartCall(false);
            return null;
          }
          return prev;
        });
      }
      console.log(window.location.pathname, selectedProfileMenuTab);
      console.log(!window.location.pathname === "/consultationCall", !showChat);
      if (
        payload?.data?.event?.toLowerCase() === "message" &&
        window.location.pathname !== "/consultationCall" &&
        !showChat
      ) {
        console.log(window.location.pathname, showChat);
        console.log(
          !window.location.pathname === "/consultationCall",
          !showChat
        );
        setInComingMassage(payload);
      }
      // else if (payload?.data?.event === "message" || payload?.data?.event === "chat") {
      //   msgTone.play()
      //   setTimeout(() => getNotificationsByIdService(dispatch, getEmployeeId()), 2000);
      // }
      // else if (payload?.data?.event === notificationType.type3) {
      //   !show2 && setShow2(true);
      //   !notification2 && setNotification2({
      //     data: payload?.data,
      //     title: payload?.notification?.title,
      //     body: payload?.notification?.body,
      //     image: payload?.notification?.image,
      //   });
      // }
    }
  };
  const sendMessage = (data) => {
    dispatch(ChatService("post", "", data));
    setIsSend(data);
    if (channel) channel.sendMessage({ text: JSON.stringify(data) });
  };
  useEffect(() => {
    if (
      userLogin &&
      primaryUser &&
      Object.keys(primaryUser).length !== 0 &&
      fireBaseTokenValue
    ) {
      const deviceId = localStorage.getItem("dv_id");
      let data;
      if (deviceId) {
        data = {
          deviceInfo: {
            deviceToken: fireBaseTokenValue,
            deviceId: deviceId,
            addVersion: null,
            osVersion: null,
            deviceModel: null,
            deviceOEM: null,
          },
          usersInfo: {
            deviceId: deviceId,
            username: primaryUser.mobileNumber,
            userId: primaryUser.patientId,
          },
        };
      } else {
        const tempId = uuidv4();
        localStorage.setItem("dv_id", tempId);
        data = {
          deviceInfo: {
            deviceToken: fireBaseTokenValue,
            deviceId: tempId,
            addVersion: null,
            osVersion: null,
            deviceModel: null,
            deviceOEM: null,
          },
          usersInfo: {
            deviceId: tempId,
            username: primaryUser.mobileNumber,
            userId: primaryUser.patientId,
          },
        };
      }
      dispatch(refreshFirebaseTokenService(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fireBaseTokenValue, primaryUser]);
  //   .catch((err) => console.log("failed: ", err));
  // console.log(isTokenFound, "isTokenFound");
  // <Switch>
  //   <Suspense fallback={renderLoader()}>
  //     <Route exact path="/">
  //       <LandingPage />
  //     </Route>
  //   </Suspense>
  // </Switch>
  const callModalSize = {
    0: {
      width: "100vw",
      height: "100vh",
      backgroundColor: "black",
    },
    1: {
      width: 350,
      height: 700,
      backgroundColor: "black",
    },
    2: {
      width: 350,
      height: 170,
      backgroundColor: "black",
    },
  };
  const transitionsVideoCall = useTransition(startCall, {
    from: {
      x: 0,
      y: 0,
      opacity: 0,
      position: "fixed",
      bottom: 0,
      left: 0,
      // width: callModalSize[selectedCallView].width,
      // height: callModalSize[selectedCallView].height,
      // width: 350,
      // height: 700,
      zIndex: 1200,
    },
    enter: { x: 0, y: 0, opacity: 1 },
    leave: { x: 0, y: -800, opacity: 0 },
  });
  useEffect(() => {
    if (incomingCall) {
      audio.play();
    } else {
      audio.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingCall]);

  useEffect(() => {
    if (inComingMassage && userLogin) {
      msgTone.play();
      // setTimeout(msgTone.pause(), 3000);
    } else {
      msgTone.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inComingMassage]);
  return (
    <ThemeContext.Provider value={data}>
      <Router>
      <ToastContainer />
        <Routes>
          <Route exact path="/account/delete-request" element={<AccountDeletionPage />} />
          <Route  path="/" element={<UnderMaintance />} />
          <Route  path="*" element={<p>404 Not Found</p>} />
        </Routes>
      </Router>
      {/* <Router history={history}>
        <Suspense fallback={<div>Loading</div>}>
          <ToastContainer />
          <Header />
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="/consultation" element={<Consultation />} />
              <Route path="/pharmacy" element={<Pharmacy />} />
              <Route path="/lab" element={<Lab />} />
              <Route
                path="/consultationBooking"
                element={<ConsultationBooking />}
              />
              <Route path="/consultationCall" element={<VideoAudioChat />} />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/account/delete-request" element={<AccountDeletionPage />} />
          </Routes>

          {filePriview?.fileOpen ? (
            <FilePreview
              open={filePriview.fileOpen}
              fileType={filePriview.fileType}
              signedUrl={filePriview.signedUrl}
              closePreview={closePriview}
            />
          ) : null}
          {patientReminder?.active && userLogin && <ReminderPopup />}
        </Suspense>

      </Router> */}
      {/* <Footer /> */}
      {/* <PublicRoute
            restricted={false}
            component={LandingPage}
            path="/"
            exact
          />
          <PublicRoute
            restricted={true}
            component={Login}
            path="/login"
            exact
            />
          <PrivateRoute component={Profile} path="/profile" exact /> */}
      {/* <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} /> */}
      {/* <Footer /> */}
      {transitionsVideoCall(
        (style, item) =>
          item && (
            <div
            // style={{
            //   display: isMinimized ? 'none' : 'block'
            // }}
            >
              <Draggable
                handle=".handle"
                defaultPosition={{ x: 0, y: 0 }}
                position={selectedCallView === 0 ? { x: 0, y: 0 } : null}
                grid={[25, 25]}
                scale={1}
                key={2}
                disabled={selectedCallView === 0}
              >
                <animated.div style={style} key={2}>
                  <div
                    className="handle"
                    style={callModalSize[selectedCallView]}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <AppBar position="static" style={{ height: "100%" }}>
                        <Toolbar
                          variant="dense"
                          style={{ height: 40, minHeight: 0 }}
                        >
                          <Box flex style={{ width: "100%", display: "flex" }}>
                            <Box
                              style={{
                                display: "flex",
                                flex: 1,
                                alignItems: "center",
                              }}
                            >
                              {notification?.data?.prof ? (
                                <Avatar
                                  style={{ width: 25, height: 25 }}
                                  src={notification?.data?.prof}
                                />
                              ) : (
                                <Avatar
                                  style={{ width: 25, height: 25 }}
                                  sx={{ bgcolor: red[500] }}
                                  aria-label="recipe"
                                >
                                  {notification?.data?.callerName
                                    ? data?.data?.callerName[0]
                                    : "D"}
                                </Avatar>
                              )}
                              <Typography marginLeft={1}>
                                {notification?.data?.callerName || "Diahome"}
                              </Typography>
                            </Box>

                            {selectedCallView !== 2 && (
                              <IconButton
                                size="small"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={() => setSelectedCallView(2)}
                              >
                                <RemoveIcon />
                              </IconButton>
                            )}
                            {/* {(selectedCallView === 0 || selectedCallView === 2) &&
                              <IconButton
                                size="small"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={() => setSelectedCallView(1)}
                              >
                                <SmartphoneIcon />
                              </IconButton>} */}
                            {(selectedCallView === 1 ||
                              selectedCallView === 2) && (
                                <IconButton
                                  size="small"
                                  aria-label="account of current user"
                                  aria-controls="menu-appbar"
                                  aria-haspopup="true"
                                  color="inherit"
                                  onClick={() => setSelectedCallView(0)}
                                >
                                  <OpenInFullIcon />
                                </IconButton>
                              )}
                          </Box>
                        </Toolbar>
                      </AppBar>
                    </Box>
                    <VideoAudioCall
                      notification={notification}
                      setNotification={setNotification}
                      sendMessage={sendMessage}
                      selectedCallView={selectedCallView}
                      setStartCall={setStartCall}
                      startCall={startCall}
                    />
                  </div>
                </animated.div>
              </Draggable>
            </div>
          )
      )}
      {incomingCall && (
        <div className="container">
          <div className="text">
            <IncomingCallPopup
              data={incomingCall}
              onAnswer={() => {
                if (!notification) {
                  setNotification(incomingCall);
                  setStartCall(true);
                  setIncomingCall(null);
                }
              }}
              onDecline={() => {
                setIncomingCall(null);
                const data = {
                  collapse_key: "type_a",
                  app: "caretaker",
                  userId: incomingCall?.data?.callerId,
                  priority: "high",
                  data: {
                    event: notificationType.type5,
                    callerId: primaryUser?.patientId,
                    callerRole: "Patient",
                  },
                  android: {
                    priority: "high",
                  },
                };
                sendPushNotificationService(data);
              }}
            />
          </div>
        </div>
      )}
      {inComingMassage && userLogin && (
        <div className="containermsg">
          <div className="textmsg">
            <IncomingMessage
              data={inComingMassage}
              onDecline={() => {
                setInComingMassage(null);
              }}
              dispatch={dispatch}
            />
          </div>
        </div>
      )}
    </ThemeContext.Provider>
  );
};

export default App;
