import { Box, Button, Card, Checkbox, CircularProgress, FormControlLabel, FormGroup, TextField, Typography } from "@mui/material";
import { useState } from "react";
import OtpInput from "react-otp-input";
import './index.css'
import { triggerToastSuccess } from "../../services/service";
import { removeAccountOnAccountDeletion, sendOtpOnAccountDeletion, verifyOtpOnAccountDeletion } from "../../services/accountDelete.service";
import { Done } from "@mui/icons-material";
import AlertDialog from "./confirmDialog";
import diahomeLogo from '../../assets/images/logos/Diahomelogo.jpg'
const AccountDeletionPage = () => {
    const [mobile, setMobile] = useState('');
    const [load, setLoad] = useState(false);
    const [isVerify, setIsVerify] = useState(0);
    const [loginData, setLoginData] = useState();
    const [patientId, setPatientId] = useState('')
    const [otp, setOtp] = useState('');
    const [isComplete, setIsComplete] = useState(false);
    const [open, setOpen] = useState(false);
    const [remarks, setRemarks] = useState('')
    const onVerify = () => {
        setLoad(true);
        sendOtpOnAccountDeletion(mobile).then(res => {
            setLoad(false);
            console.log(res?.data, 'ascfaivqhcv')
            if (res?.data?.data?.patientId) {
                setPatientId(res?.data?.data?.patientId)
                setIsVerify(1)
                triggerToastSuccess(`OTP sent to ${mobile}`)
            }
        })
    }
    const onOtpChange = (val) => {
        console.log(val, '78576567');
        setOtp(val)
    }
    const onSubmit = () => {
        setLoad(true);
        verifyOtpOnAccountDeletion(patientId, otp).then(res => {
            if (res?.data) {
                setIsVerify(2);
                setLoginData(res?.data?.data)
                setOtp('');
                setMobile('')
                // triggerToastSuccess('Request Submitted')
            }
            setLoad(false);
        })
        setTimeout(() => {
        }, 2000)
    }
    const [isError, setIsError] = useState(false);
    const removeAccount = () => {
        setLoad(true);
        console.log(loginData, 'qwefqdfqwd')
        removeAccountOnAccountDeletion(patientId, loginData?.accessToken, remarks).then(res => {
            if (res?.data) setIsComplete(true)
            setLoad(false);
        })
    }
    return (
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', p: 3 }}>
            <Card sx={{ p: 4, boxShadow: 'none' }}>
                <img width={150} height={40} src={diahomeLogo} />
                {(isVerify === 0 && !isComplete) ?
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3 }}>
                        <Typography variant="h6">Account delete request</Typography>
                        <TextField
                            label="Mobile No"
                            value={mobile}
                            onChange={(e) => {
                                const val = e.target?.value?.replace(/[^0-9]/g, '')
                                if (val?.length <= 10 && +val >= 0) {
                                    setMobile(val)
                                }
                            }}
                            size="small" />
                        {load ?
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress size={20} />
                            </Box>
                            :
                            <Button disabled={mobile.length !== 10} onClick={onVerify}>Verify</Button>
                        }
                    </Box>
                    :
                    ((patientId && isVerify === 1 && !isComplete)) ?
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3 }}>
                            <Typography textAlign={'center'} variant="h6">Account delete request</Typography>
                            <OtpInput
                                inputStyle="inputStyle"
                                value={otp}
                                isInputNum={true}
                                onChange={onOtpChange}
                                numInputs={6}
                                separator={<span>-</span>}
                            />
                            <Typography onClick={() => {
                                setOtp('')
                                onVerify()
                            }} color={'skyblue'} sx={{ cursor: 'pointer' }} textAlign={'end'} variant="body2">Resend OTP?</Typography>
                            {
                                load ?
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <CircularProgress size={20} />
                                    </Box>
                                    : <Button disabled={otp.length !== 6} onClick={onSubmit}>Submit Request</Button>
                            }
                        </Box>
                        : (isVerify === 2 && loginData && !isComplete) ?
                            <Box sx={{mt:2}}>
                                <Typography><b>Email</b>: &nbsp;{loginData?.email || '--'}</Typography>
                                <Typography mt={1}><b>Mobile No</b>: &nbsp;{loginData?.username || '--'}</Typography>
                                <TextField error={isError && !remarks} value={remarks} onChange={(e) => setRemarks(e.target.value)} sx={{ mt: 2, p: 0 }} label="Reason for Deletion*" InputLabelProps={{ shrink: true }} />
                                {/* <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked />} label="Send documents to my email" />
                                </FormGroup> */}
                                {load ?
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt:3 }}>
                                        <CircularProgress size={20} />
                                    </Box>
                                    :
                                    <Box mt={2}>
                                        <Button onClick={() => {
                                            if (!remarks) return setIsError(true);
                                            setOpen(true)

                                        }} color="error">Remove My Account</Button>
                                    </Box>}
                            </Box>
                            : null

                }

                {
                    isComplete ?
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Done color="success" />
                            <Typography ml={1}>
                                Request Submitted
                            </Typography>
                        </Box>
                        : null
                }
            </Card>
            <AlertDialog open={open} setOpen={setOpen} onConfirm={removeAccount} />

        </Box>

    )
}

export default AccountDeletionPage;