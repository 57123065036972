import { getCategoryList, loadingShow } from "../../services/service";

export const getCategoryMaster = (_c) => async (dispatch) => {
  loadingShow(dispatch, true);
  let categoryList = [];
  if (_c) {
    categoryList = _c;
  } else {
    categoryList = [
      "Title",
      "BloodGroup",
      "EmployeeType",
      "JobType",
      "SalaryType",
      "EducationLevel",
      "Gender",
      "Designation",
      "Specialists",
      "CourseList",
      "Department",
      "Language",
    ];
  }

  categoryList.map(async (c) => {
    const data = await dispatch(getCategoryList(c));
    if (data && data.items && data.items.length > 0) {
      dispatch({
        type: "GET_CATEGORY",
        payload: data,
        name: c.toLowerCase(),
      });
    }
    loadingShow(dispatch, false);
  });
};
