const initialState = {
  loadingScreen: false,
  loading: false,
  globalGender: [],
  bannerCheck: {},
  notificationCount: 0,
  notificationMessages: {},
  bookingtype: "",
  bannerInfo: [],
  orderTabType: 1,
  vitalMaster: [],
  vitalField: [],
  patientSubscription: [],
  getSubscription: [],
  getPopup: [],
  patientReminder: [],
  userHealthRecord: {},
  filePriview: {
    fileOpen: false,
    fileType: null,
    signedUrl: null,
  },
};
const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PATIENT_SUBSCRIPTION":
      return {
        ...state,
        patientSubscription: action.payload,
      };
    case "GET_SUBSCRIPTION":
      return {
        ...state,
        getSubscription: action.payload,
      };
    case "BANNER_CHECK_AVAILABILITY":
      return {
        ...state,
        bannerCheck: action.payload,
      };
    case "FILE_PRIVIEW":
      return {
        ...state,
        filePriview: action.payload,
      };
    case "GET_PATIENT_POPUP":
      return {
        ...state,
        patientReminder: action.payload,
      };
    case "GET_POPUP":
      return {
        ...state,
        getPopup: action.payload,
      };
    case "VITAL_FILED":
      return {
        ...state,
        vitalField: action.payload,
      };
    case "LOADING_SCREEN":
      return {
        ...state,
        loadingScreen: action.payload,
      };
    case "LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "USER_HEALTH_RECORD":
      return {
        ...state,
        userHealthRecord: action.payload,
      };
    case "GET_CATEGORY":
      return {
        ...state,
        [action.name]: action.payload,
      };
    case "NOTIFICATION_COUNT":
      return {
        ...state,
        notificationCount: action.payload,
      };
    case "NOTIFICATION_MESSAGES":
      return {
        ...state,
        notificationMessages: action.payload,
      };
    case "HEALTH_BANNER":
      return {
        ...state,
        bannerInfo: action.payload,
      };
    case "BOOKING_TYPE":
      return {
        ...state,
        bookingtype: action.payload,
      };
    case "ORDER_TAB":
      return {
        ...state,
        orderTabType: action.payload,
      };
    case "VITALS_MASTER":
      return {
        ...state,
        vitalMaster: action.payload,
      };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
export default globalReducer;
