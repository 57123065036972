const initialState = {
  loginStep: 1,
  mobileNumber: "",
  verfyOtp: "",
  patientId: "",
  loginType: "",
  userLogin: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_STEP":
      return {
        ...state,
        loginStep: action.payload,
      };
    case "LOGIN_MOBILE_NUMBER":
      return {
        ...state,
        mobileNumber: action.payload,
      };
    case "LOGIN_VERIFY_OTP":
      return {
        ...state,
        verfyOtp: action.payload,
      };
    case "LOGIN_PATIENT_ID":
      return {
        ...state,
        patientId: action.payload,
      };
    case "LOGIN_TYPE":
      return {
        ...state,
        loginType: action.payload,
      };
    case "USER_LOGIN":
      return {
        ...state,
        userLogin: action.payload,
      };
    default:
      return state;
  }
};

export default loginReducer;
