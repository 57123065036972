import moment from "moment";

export const isLogin = true;
export const getToken = () => {
  return localStorage.getItem("accessToken");
};
export const clearAllLocalStorage = () => {
  return localStorage.clear();
};
export const getUserOnlineStatus = async (clientRTM, peerId) => {
  const res = await clientRTM.queryPeersOnlineStatus([peerId]);
  if (typeof res === "object") return res[peerId];
};
export const notificationTypeForOnClick = {
  type1: "casesheet",
  type2: "customer_support",
  customerSupportReqListId: "customerSupportReqListId",
};
export const isGivenDateTimeInFuture = (data) => {
  const { date, time, dateFormat, timeFormat, graceTimeInMinutes } = data;
  // console.log(data);
  const exemptionTime = graceTimeInMinutes || 0;
  const todayDate = moment();
  const givenDate = moment(date, dateFormat);
  const todayDate1 = moment().format("DD-MM-YYYY");
  const givenDate1 = moment(date, dateFormat).format("DD-MM-YYYY");
  const diff = givenDate.diff(todayDate);
  if (todayDate1 === givenDate1) {
    const givenTime = moment(time, timeFormat);
    // console.log(
    //   diff,
    //   date,
    //   time,
    //   givenTime.diff(moment(), "minutes") >= exemptionTime,
    //   "difffffff2222"
    // );
    return givenTime.diff(moment(), "minutes") >= exemptionTime;
  } else if (diff > 0) return true;
  else return false;
};
export const notificationType = {
  type1: "call",
  type2: "message",
  type3: "JOIN_REQUEST",
  type4: "chat",
  type5: "reject_call",
};
export const agoraCallChannelPreFix = "RTC";
export const diahomeapp = [
  {
    id: 1,
    title: "Where should you download the app?",
    des: "Diahome App is available on Google Play Store and Apple Store. Search for “Diahome” in the listing, then click “download and install.“. ",
  },
  {
    id: 2,
    title: "Are you having trouble downloading the app?",
    des: "The following steps may help you download the application:\n ◉ Please make sure that you have your Google Play store (Android) or App Store (iPhone/iPad) log-in credentials.\n ◉ Make sure you have enough storage on your device to install the app ",
  },
  {
    id: 3,
    title: "To run the app, which features does it require access to?",
    des: "The app requires access to your photo gallery, your camera, your microphone, and your GPS location after installation.\n ◉ Gallery – Upload Reports/Images \n ◉ Microphone & Camera: For online doctor consultations \n ◉ GPS location – Facilitates the booking of teleconsultations/medicine/lab tests.  ",
  },
  {
    id: 4,
    title: "What is the Diahome Registration Process?",
    des: "To start the registration process, click on the “Create New” button on the app’s home screen. ",
  },
  {
    id: 5,
    title: "What are the Mandatory details required for Registration?",
    des: "Please provide your mobile number, name, date of birth, email address, and your choice of a preferred language.",
  },
  {
    id: 6,
    title: "What are the non-mandatory details requested for registration?",
    des: "The address should include a city, state, and zip code. By providing these details upon registration, orders for lab sample collection and medicine delivery are convenient. ",
  },
  {
    id: 8,
    title: "How do you book appointments?",
    des: "Select the “Book Appointment” button to schedule a consultation with a diabetologist, dietitian, or a diabetes educator. ",
  },
  {
    id: 9,
    title: "How can you order medicines on the app?",
    des: "In your Diahome app, you can click on the “Order Medicine” tab on the home screen to order the medicine immediately or later. Here you will find all the medications on the doctor’s prescription. Using the online ordering system, you can place orders for medicines.",
  },
  {
    id: 10,
    title: "How can you request home testing via the app?",
    des: "By clicking the “Order Lab Test” button, you can have lab samples collected at your home. As soon as you make your order, you will receive the complete list of lab tests your doctor has ordered. Note: this feature is only available after the first Doctor consultation is completed. ",
  },
  {
    id: 11,
    title: "Are the previous consultation records available to you?",
    des: "You can access your previous diabetologist, dietitian, and diabetes educator consultations by clicking the “Past Consultations” button.",
  },
  {
    id: 12,
    title: "Where can you check the date and time of your appointment?",
    des: "By clicking on the “Upcoming Appointments” button, you can view the date and time of your appointment with your Diabetologist, Dietitian, and/or Diabetes Educator. ",
  },
  {
    id: 13,
    title:
      "Is it possible to book an appointment for your family member(s) on the app?",
    des: "Although there can only be one person registered per app, the “My Family” button will allow multiple users to register. Using this feature, you can add your family members by their name, your relationship, and medical information. With just one login, you can schedule appointments for any member of your family. ",
  },
  {
    id: 14,
    title: "If you’re not diabetic, is the app still useful for you?",
    des: "◉ If you are over 40 or if you have noticed any symptoms of Diabetes, you should consult a Diabetologist.  \n ◉ By detecting and treating diabetes early, a healthy lifestyle can be achieved. \n ◉ It is also possible to book appointments for family members who may need consultations.",
  },
  {
    id: 15,
    title: "What is the ‘Home’ button on app?",
    des: "You can return to the home screen by clicking this button.  ",
  },
  {
    id: 16,
    title: "What is the ‘My Consults’ button on app?",
    des: "You can access your most recent and upcoming consultations using this button. ",
  },
  {
    id: 17,
    title: "What is the ‘My Orders’ button on app?",
    des: "By clicking this button, you can access all the orders you’ve placed through this app. ",
  },
  {
    id: 18,
    title: "What is the ‘More’ button on app?",
    des: "To access Terms and Conditions, Privacy Policy, and Logout, press this button.",
  },
];
export const OnlineConsultation = [
  {
    id: 1,
    title: "How to book an appointment?",
    des: "Follow these steps to book an appointment: \n ◉ Click the “Book Appointment” button on your Home Screen\n ◉ Select the name of the person you want the appointment (For you or your family) \n ◉Decide who you want to see – Diabetologist, Dietitian or Diabetes Educator ",
  },
  {
    id: 2,
    title: "Is it possible for you to choose your Diabetologist?",
    des: "A doctor of your choice can be scheduled for an appointment. A complete list of all available Doctors along with their degree, experience, and consultation fee is available for you to consider. ",
  },
  {
    id: 3,
    title: "Do I have to pay for a dietitian consultation?",
    des: "No! A dietitian consultation is part of our value added service. When you book a doctor’s consultation, you automatically are eligible for the services.",
  },
  {
    id: 4,
    title: "How do you choose an appointment time that is convenient for you?",
    des: "Following your selection of a Doctor, you will be shown a list of available dates and appointment slots for that Doctor. Select an appointment slot that is convenient for you and confirm it. ",
  },
  {
    id: 5,
    title: "How many days in advance can you book an appointment?",
    des: "A 30-day calendar is available for booking appointments. ",
  },
  {
    id: 6,
    title: "Can you book an immediate consultation?",
    des: "Booking an appointment can be done within 30 minutes of your arrival. ",
  },
  {
    id: 7,
    title: "What does it mean if a time slot is not available for booking?",
    des: "◉ Appointments must be made at least 30 minutes in advance.\n ◉There is a 15-minute slot for each appointment.  \n ◉If your preferred slot is not available, either the doctor is unavailable at that time or another patient has already booked the time. ",
  },
  {
    id: 8,
    title: "Can you book an emergency consultation?",
    des: "You can book an appointment just 30 minutes ahead, but your appointment will be based on the Doctor’s availability. If you have an emergency, you are advised to visit an emergency medical facility in person. ",
  },
  {
    id: 9,
    title: "How do you know the appointment is confirmed?",
    des: "◉ A confirmation message will appear on your mobile device after you have made your payment.  \n ◉ Additionally, you will receive a confirmation email in your registered email box.  \n ◉ The appointment will appear under “Upcoming Appointments” on the app’s home screen.   ",
  },
  {
    id: 10,
    title: "Will you get an appointment reminder?",
    des: "We will send you an email and SMS 15 minutes before the appointment.   ",
  },
  {
    id: 11,
    title: "◉ When and how do you pay for the consultation?",
    des: "When booking an appointment, payment for the consultation is required.  \n ◉You will be connected to a third-party Payment Gateway after choosing the date and time for your visit. \n ◉Once the payment has been made, an appointment will be confirmed. ",
  },
  {
    id: 12,
    title: "What payment options are available?",
    des: "We offer several payment options.  Credit cards, debit cards, UPI, QR codes, wallets, net banking, and pay later are all accepted forms of payment.",
  },
  {
    id: 13,
    title: "Can you cancel an appointment? ",
    des: "Currently, the cancellation feature is not available. However, you can reschedule the appointment to another date. ",
  },
  {
    id: 14,
    title: "How do you reschedule an appointment?",
    des: "Requests for rescheduling appointments can be made in the chatbox of the appointment. Upon receiving this request, the physician assistant will send you options for rescheduling.   ",
  },
  {
    id: 15,
    title: "Can you get a refund?",
    des: "Rescheduling can be requested if you cannot attend a previously booked appointment slot. Unfortunately, cancellations and refunds cannot be offered at this time.  ",
  },
  {
    id: 16,
    title: "What is the ‘My Consults’ button on app?",
    des: "You can access your most recent and upcoming consultations using this button. ",
  },
  {
    id: 17,
    title: "How late can you request a reschedule?",
    des: "◉ It is possible to request a reschedule up to an hour in advance. \n ◉Any reschedule request after 1 hour before the appointment slot will not be accepted. \n ◉The fee paid will be forfeited if you fail to reschedule and do not appear at the appointment time. ",
  },
  {
    id: 18,
    title: "Where is the ‘Reschedule Appointment’ button?",
    des: "After you submit a request for rescheduling with the physician assistant, he will be able to approve it. After that, you’ll see a reschedule button under “Upcoming Appointment.“.   ",
  },
  {
    id: 19,
    title: "Does Diahome ever reschedule appointments?",
    des: "You may be requested to reschedule an appointment if: \n ◉ The Doctor is unavailable (due to emergencies and/or other reasons) \n ◉ If your lab reports are unavailable or delayed You will be informed why the appointment has to be rescheduled and you will have the option to select an alternate slot by clicking the “Reschedule Appointment” button. ",
  },
  {
    id: 20,
    title: "How do you upload your documents?",
    des: "The chat section of the appointment allows you to upload photos/documents (jpeg/png/pdf/word doc). ",
  },
  {
    id: 21,
    title: "Can you talk to the Doctor before the consultation?",
    des: "Prior to your appointment, the Physician Assistant can help you speak to the doctor. If the situation demands it, you can also ask the Physician Assistant to take a video call with you via the Appointment chatbox. ",
  },
  {
    id: 22,
    title: "Who is a Physician Assistant?",
    des: "Essentially, a Physician Assistant is your health consultant, working under the supervision of your physician. Prior to the appointment, the PA will record your questions/concerns, review your uploaded documents, and coordinate lab tests and medicine orders. ",
  },
  {
    id: 23,
    title: "What if I need to talk to someone before the appointment?",
    des: "Upon booking an appointment, the Physician Assistant will call you to set up a convenient time to speak. During this call, you can share any questions or concerns you have. ",
  },
  {
    id: 24,
    title: "How can I share my medical history before the appointment?",
    des: "Before the first appointment, your Physician Assistant will utilize the Diahome app to gather all your medical history. If any lab tests are necessary before the appointment, the PA will advise you. ",
  },
  {
    id: 25,
    title: "Will the Doctor have your complete medical history?",
    des: "Indeed. The Physician Assistant relays all your medical history to your doctor, who will:\n ◉Before your first call, make a record of your history \n ◉Prior to the first call, please upload your medical records \n ◉During your doctor’s consultation, record all the proceedings\n ◉After the consultation, follow up to ensure medicines and lab tests are completed. ",
  },
  {
    id: 26,
    title: "How does the teleconsultation process begin? Who will call you?",
    des: "For emergency and last-minute appointments, the Doctor will contact you directly.  \n ◉In your first Diahome appointment, the Physician Assistant will take notes and order tests, if necessary. Once the last test results are available, an appointment with the Doctor will be scheduled. The appointment will be rescheduled if the test results are delayed. \n ◉A Physician Assistant will initiate a call with the Doctor after receiving the lab test results. \n ◉For second and subsequent consultations, the consultation can be started right at the appointment time. You can join the consulting room or wait for the Physician Assistant to initiate the call. As part of the consultation, the physician will review the latest tests and treatment plan, renew or edit prescriptions, and order additional tests. ",
  },
  {
    id: 27,
    title: "Can you start the online consultation?",
    des: "You will be able to join the consultation room via your home screen.\n ◉When the consultation begins, a video button appears.\n ◉Both the Physician Assistant and Doctor will be present in the consultation room. ",
  },
  {
    id: 28,
    title: "Is it possible to leave the consultation and return later?",
    des: "The call will be disconnected if you leave the consultation.\n ◉The Physician Assistant can re-initiate the call if the call drops for connectivity reasons (Within the consultation time)  \n ◉However, reconnecting would not be possible past the appointment time slot. ",
  },
  {
    id: 29,
    title: "What options are available on your video consultation screen?",
    des: "Audio, video, and microphone are all toggleable.  \n ◉Camera views can be changed.  ",
  },
  {
    id: 30,
    title:
      "Is there a way to upload documents or photos during the consultation?",
    des: "",
  },
  {
    id: 31,
    title:
      "Is there a way to upload documents or photos during the consultation?",
    des: "During the consultation, you are not allowed to upload any documents or photos. Document uploads must be completed before the consultation. ",
  },
  {
    id: 32,
    title: "Are you having trouble connecting to the video call?",
    des: "You might have trouble connecting to the video call if your internet is slow. Follow these steps: \n ◉During the medical history recording, you could request a video call with the Physician Assistant and check your network’s bandwidth. \n ◉If your bandwidth is temporarily limited, you can switch off the video and consult your doctor by phone. \n ◉Drop off and reconnect \n ◉Please note: If the call is in progress and connectivity issues aren’t resolved, rescheduling may not be possible. ",
  },
];
export const Pharmacy = [
  {
    id: 1,
    title: "Where to order the medicine?",
    des: "In your Diahome app, you can click on the “Order Medicine” tab on the home screen to order the medicine immediately or later.",
  },
  {
    id: 2,
    title: "How to order medicine?",
    des: "Go to the “Order Medicine” tab and click the “Place Order” button\n ◉The list of medications will be available for you to view.  \n ◉You can edit the number of units required for each medicine. \n ◉You can edit the delivery address.  \n ◉Upon confirmation, you’ll be directed to a third-party payment gateway. You can pay with a credit card, debit card, UPI, QR code, Wallet, Net banking and Pay Later. ",
  },
  {
    id: 3,
    title: "Are you notified when the pharmacy order is placed?",
    des: "We will send you a confirmation email after we receive your order. Your pharmacist will also contact you to verify when the medicine has been shipped. ",
  },
];
export const Lab = [
  {
    id: 1,
    title:
      "If you’ve never used the app before, what lab tests do you need to perform before your first visit?",
    des: "Before the appointment, the physician assistant will gather your medical history and order the lab tests you need. ",
  },
  {
    id: 2,
    title: "How much do the lab tests cost?",
    des: "The app displays the cost of each lab test separately, so you always know what to expect. ",
  },
  {
    id: 3,
    title: "Do you have the option to pick the date and time of home testing?",
    des: "Currently, this feature is not available. Once you order the lab tests, a lab executive will contact you to schedule your blood collection.",
  },
  {
    id: 4,
    title: "How do you pay for your lab tests?",
    des: "After your first consultation, the tests ordered by the doctor will appear under the “Lab Tests” tab. \n ◉ If you click the “Place Order” button, you will be taken to the lab test pricing page.  \n ◉Here, you can also edit the address where you want the blood collection to be done. \n ◉Upon confirmation, you’ll be directed to a third-party payment gateway. You can pay with a credit card, debit card, UPI, QR code, Wallet, Net banking and Pay Later. ",
  },
  {
    id: 5,
    title:
      "Do you have the option to pick and choose which lab tests to perform?",
    des: "We do not offer this feature. In accordance with the doctor’s instructions, the PA orders the tests. It is therefore necessary to perform all the tests throughout. In any case, the PA can explain why certain tests are required if necessary.",
  },
  {
    id: 6,
    title: "Who will collect your blood sample?",
    des: "An official from Diahome will pick up your blood samples at a time convenient to you. ",
  },
  {
    id: 7,
    title: "Will you be notified about who will pick up your blood samples?",
    des: "Yes, the lab executive will send notifications and reminders with all the necessary information, like the executive’s name, phone number, and photo. ",
  },
  {
    id: 8,
    title: "What cities are currently offering Home Lab tests?",
    des: "Besides home blood collection, other measurements such as height, weight, waist circumference, blood pressure, and pulse (anthropometry measurements) are currently available in Chennai, Coimbatore, Trichy, Bengaluru, New Delhi, Hyderabad, Mumbai, Kolkata, Ahmedabad, Pune, and Jaipur.  Blood collection is only available in Pondicherry, Madurai, Salem, Vellore, Erode, Tirupur, Pollachi, Karur, Dharapuram, Dharmapuri, Oddanchatram, Dindigul, Palani, Cuddalore, Sholinghur, Ranipet, Arani, Arcot, Cheyyar, Vandavasi, Tiruvannamalai, Virudhachalam, and Calicut.",
  },
  {
    id: 9,
    title:
      "What procedures should you follow before and after the collection of your blood?",
    des: "A fast of at least eight hours is required before blood collection.  \n ◉While fasting, you may only drink water. \n ◉After blood collection, you can break your fast and take your regular medication after food intake. \n ◉A postprandial blood collection is performed one hour and thirty minutes after you break your fast.  \n ◉Breakfast is recommended immediately after blood collection. ",
  },
  {
    id: 10,
    title:
      "If the collection person does not arrive on time, what should you do? ",
    des: "The collection executive’s phone number would be provided to you\n ◉To find out when they are coming, you may call them.  \n ◉If they are not reachable, you can call our helpline number.  \n ◉We will reschedule the collection for a later date if the collection executive arrives 30 minutes later than the scheduled time.\n ◉Blood test results will be ready by the end of the day.\n ◉Before your consultation, it will be uploaded to the Diahome app.\n ◉You can reschedule the date and time by calling the helpline number.\n ◉Should the doctor’s scheduled appointment change, the physician assistant will call you and ask to reschedule.       ",
  },
  {
    id: 11,
    title: "When will you get your blood report?",
    des: "Once the blood has been collected, blood reports are ready by the end of the day. Before your consultation, your doctor will be able to see your test results on the app. ",
  },
  {
    id: 12,
    title: "Is it possible to reschedule the blood collection time and date?",
    des: "Is it possible to reschedule the blood collection time and date?",
  },
  {
    id: 13,
    title: "Is it possible to reschedule the blood collection time and date?",
    des: "By calling the helpline number, you can reschedule the date and time. Please check with your doctor’s assistant in case the appointment time gets changed. ",
  },
];
