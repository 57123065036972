import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBannerPackageandTestCheck,
  getPatientAddress,
} from "../services/service";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import AddIcon from "@mui/icons-material/Add";
import { blue } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

const SelectAddress = (props) => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { selectedUserAddress, selectedAddress, selectedUser } = useSelector(
    (state) => state.usersReducer
  );
  const { bookingtype, bannerCheck } = useSelector(
    (state) => state.globalReducer
  );
  useEffect(() => {
    dispatch(getPatientAddress());
  }, []);
  useEffect(() => {
    const findAddress = selectedUserAddress.find(
      (val) => val.id === selectedAddress.id
    );
    console.log(findAddress, "findAddress");
    if (!findAddress) {
      dispatch({
        type: "USERS_SELECTED_ADDRESS",
        payload: {},
      });
    }
  }, [selectedUserAddress]);
  return (
    <List sx={{ pt: 0 }}>
      {selectedUserAddress.map((address) => (
        <ListItem
          button
          key={address.id}
          sx={{
            bgcolor: selectedAddress.id === address.id ? "#fabb62" : "inherit",
            color: selectedAddress.id === address.id ? "white" : "inherit",
          }}
          onClick={() => {
            // const findMember = familyMembers.find((val) => val.patientId === member.patientId);
            dispatch({ type: "USERS_SELECTED_ADDRESS", payload: address });
            if (
              bookingtype === "Test Banner" ||
              bookingtype === "Package Banner"
            ) {
              dispatch(
                getBannerPackageandTestCheck(
                  address.pincode ? address.pincode : "",
                  bannerCheck?.type,
                  bannerCheck?.ptId,
                  selectedUser.referralCode ? selectedUser.referralCode : ""
                )
              );
            }
          }}
        >
          <ListItemAvatar>
            <Avatar
              sx={{
                backgroundColor:
                  address.modeOfAddress === "OFFICE"
                    ? "info.light"
                    : address.modeOfAddress === "HOME"
                    ? "primary.main"
                    : "warning.light",
              }}
              aria-label="recipe"
            >
              {address.modeOfAddress === "HOME" ? (
                <HomeIcon />
              ) : address.modeOfAddress === "OFFICE" ? (
                <WorkIcon />
              ) : (
                <AddLocationAltIcon />
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <Typography variant="body2" color="text.secondary">
                  {address.street}, {address.area}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {address.city} - {address.pincode}
                </Typography>
              </>
            }
          />
        </ListItem>
      ))}

      <ListItem
        autoFocus
        button
        onClick={() => {
          props.props.setShowModel(false);
          nav("/profile");
          dispatch({ type: "PROFILE_MENU_TAB", payload: 2 });
          setTimeout(() => {
            dispatch({ type: "USERS_ADDRESS_TAB", payload: 1 });
          }, 1000);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <AddIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Add Address" />
      </ListItem>
    </List>
  );
};

export default SelectAddress;
