const initialState = {
  laboptions: 0,
  packList: {},
  testList: {},
  selectedPackage: [],
  selectedTest: [],
  selectedTestLab: {},
  selectedTestCost: [],
  updateCart: {},
  getCart: {},
  getSlot: {},
  selectedSlot: {},
  bookedSlot: {},
  conformLabOrder: {},
  checkCod: {},
  labPromoCode: {},
  labPay: {},
  testTab: 0,
  loadData: true,
  upcoming: [],
  labBooking: {},
  labTrackData: {},
  labTab: 0,
  myPackage: [],
  getBookedSlotdata: [],
  selectedBookedSlotData: {},
  updateslotData: {},
  bookingSlotUpdateOrder: [],
  labOrderSuccessPopup: {},
  patientLabOrder: {},
};
const labReducer = (state = initialState, action) => {
  switch (action.type) {
    case "BOOK_SLOT_DATA":
      return {
        ...state,
        selectedBookedSlotData: action.payload,
      };
    case "PATIENT_LABORDER":
      return {
        ...state,
        patientLabOrder: action.payload,
      };
    case "LAB_ORDER_SUCCESSPOPUP":
      return {
        ...state,
        labOrderSuccessPopup: action.payload,
      };
    case "GET_BOOKED_SLOT":
      return {
        ...state,
        getBookedSlotdata: action.payload,
      };
    case "BOOKED_PACKAGE_SLOT":
      return {
        ...state,
        bookedSlot: action.payload,
      };
    case "LAB_BOOKING_SLOTDATA":
      return {
        ...state,
        bookingSlotUpdateOrder: action.payload,
      };
    case "MY_PACKAGE":
      return {
        ...state,
        myPackage: action.payload,
      };
    case "LAB_UPDATE_SLOT":
      return {
        ...state,
        updateslotData: action.payload,
      };
    case "LAB_OPTIONS":
      return {
        ...state,
        laboptions: action.payload,
      };
    case "LAB_TAB_OPTIONS":
      return {
        ...state,
        labTab: action.payload,
      };
    case "LAB_BOOKING_INFO":
      return {
        ...state,
        labBooking: action.payload,
      };
    case "LAB_TRACKING":
      return {
        ...state,
        labTrackData: action.payload,
      };
    case "PACKAGE_LIST":
      return {
        ...state,
        packList: action.payload,
      };
    case "TEST_LIST":
      return {
        ...state,
        testList: action.payload,
      };
    case "SELECTED_PACKAGE_INFO":
      return {
        ...state,
        selectedPackage: action.payload,
      };
    case "SELECTED_TEST_INFO":
      return {
        ...state,
        selectedTest: action.payload,
      };
    case "SELECTED_TEST_LAB_INFO":
      return {
        ...state,
        selectedTestLab: action.payload,
      };
    case "SELECTED_TEST_COST_INFO":
      return {
        ...state,
        selectedTestCost: action.payload,
      };
    case "GET_CART_DATA":
      return {
        ...state,
        getCart: action.payload,
      };
    case "UPDATE_CART_DATA":
      return {
        ...state,
        updateCart: action.payload,
      };
    case "GET_SLOT_DATA":
      return {
        ...state,
        getSlot: action.payload,
      };
    case "SELECTED_SLOT":
      return {
        ...state,
        selectedSlot: action.payload,
      };
    case "CONFORM_LAB_ORDER":
      return {
        ...state,
        conformLabOrder: action.payload,
      };
    case "CHECK_COD":
      return {
        ...state,
        checkCod: action.payload,
      };
    case "LAB_PROMO_CODE":
      return {
        ...state,
        labPromoCode: action.payload,
      };
    case "LAB_PAY_DATA":
      return {
        ...state,
        labPay: action.payload,
      };
    case "ORDER_TAB_TEST":
      return {
        ...state,
        testTab: action.payload,
      };
    case "LAB_LOAD":
      return {
        ...state,
        loadData: action.payload,
      };
    case "UPCOMING":
      return {
        ...state,
        upcoming: action.payload,
      };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
export default labReducer;
