import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import profileImage from "../../assets/images/users/profilePic.png";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TelegramIcon from "@mui/icons-material/Telegram";
import { ThemeContext } from "../../App";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  agoraCallChannelPreFix,
  getUserOnlineStatus,
  notificationTypeForOnClick,
} from "../../utils/utils";
import {
  ChatService,
  fileAttachmentUploadURLGenName,
  getAgoraRTCToken,
  getChatHistoryService,
  sendAndSavePushNotificationService,
  sendPushNotificationService,
  triggerToastWarn,
} from "../../services/service";
import MyMsgComponent from "./MyMsgComponent";
import ReceivedMsgComponent from "./ReceivedMsgComponent";
import moment from "moment";
import Draggable from "react-draggable";
import { getCategoryMaster } from "../../store/actions/masterAction";
import { DropzoneArea } from "react-mui-dropzone";
import InfoIcon from "@mui/icons-material/Info";
import profilePic from "../../assets/images/users/profilePic.png";
import { fontData } from "../style.js/StyleData";
// import ScrollToBottom, { useScrollToBottom, useSticky } from "react-scroll-to-bottom";
const schema = yup.object().shape({
  documentName: yup.string().required(),
  documentType: yup.string().required(),
  fileName: yup.string().required(),
  fileType: yup.string().required(),
  isActive: yup.bool(),
  notes: yup.string().required(),
});
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const VideoAudioChat = () => {
  const onLineRef = useRef();
  const dispatch = useDispatch();
  // const scrollToBottom = useScrollToBottom();
  // const [sticky] = useSticky();

  const scrollRef = useRef();
  const { clientRTM, startCallFun } = useContext(ThemeContext);
  const { selectedConsultation } = useSelector(
    (state) => state.consultationReducer
  );
  const { selectedUser, primaryUser } = useSelector(
    (state) => state.usersReducer
  );
  const { profileupload } = useSelector((state) => state.globalReducer);
  const [isPatLoggedIn, setIsPatientLoggedIn] = useState(false);
  const [client, setClient] = useState({});
  // const [audio, setAudio] = useState(false);
  const [channelAgora, setChannelAgora] = useState(null);
  const [memberCount, setMemberCount] = useState([]);
  const [typeMessage, setTypeMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [receivedDeletedMsgId, setReceivedDeletedMsgId] = useState(null);
  const [agoraRtm, setAgoraRtm] = useState({});
  const onInitAgora = async () => {
    console.log(selectedConsultation, "selectedConsultation");
    setClient(clientRTM);

    const channel2 = await clientRTM.createChannel(
      selectedConsultation.casesheetId
    );
    await channel2.join();
    console.log(channel2, "joined");
    setAgoraRtm(channel2);
    setChannelAgora(channel2);
    if (channel2) {
      const getMember = () => {
        channel2.getMembers(selectedConsultation.casesheetId).then((res) => {
          console.log(res, "getMembers");
          setMemberCount(res);
        });
      };
      channel2.on("ChannelMessage", function (message, memberId) {
        const temp = JSON.parse(message.text);
        console.log(temp, "123456789");
        if (temp?.message?.event === "update")
          return setReceivedDeletedMsgId(temp?.message?.text);
        else if (temp?.message?.event) {
          setMessages((msg) => [
            ...msg,
            {
              id: temp?.id,
              isActive: true,
              message: {
                event: temp?.message?.event,
                senderId: temp?.message?.senderId,
                senderName: temp?.message?.senderName,
                text: temp?.message?.text,
                fileType: temp?.message?.fileType,
                time: temp?.message?.time,
              },
            },
          ]);
        }
      });
      getMember();
      channel2.on("MemberJoined", function (memberId) {
        console.log(memberId + " joined the channel");
        getMember();
      });
      channel2.on("MemberLeft", function (memberId) {
        console.log(memberId + " left the channel");
        getMember();
      });
    }
  };

  const getConsultationPersonIdForCall = () => {
    if (selectedConsultation?.paId?.length)
      return selectedConsultation?.paId[selectedConsultation?.paId?.length - 1]
        ?.empId;
    else return selectedConsultation?.doctorId;
  };
  useEffect(() => {
    clientRTM && onInitAgora();
  }, [clientRTM]);
  useEffect(() => {
    const scroll =
      scrollRef.current.scrollHeight - scrollRef.current.clientHeight;
    // scrollRef.current.scrollTo(0, scroll);
    scrollRef.current.scrollTo({
      top: scroll,
      left: 0,
      behavior: "smooth",
    });
  }, [messages]);
  const leave = async (channel2) => {
    console.log(channel2, "channel2");
    if (channel2) {
      console.log("jnskdnc");
      const res = await channel2?.leave();
      console.log(res, "jnskdnc");
      // setChannel(null);
      setAgoraRtm({});
    }
    // if (client) await client.logout();
    console.log("logged out");
  };
  const getInitMessages = async (casesheetId) => {
    const chatHistoryData = await getChatHistoryService(casesheetId);
    setMessages(chatHistoryData);
  };
  const [rtcToken, setRtcToken] = useState(null);
  const getRtcToken = async () => {
    let tok;
    const res = await getAgoraRTCToken(
      0,
      selectedConsultation.casesheetId + agoraCallChannelPreFix
    ).then((data) => {
      setRtcToken(data);
      tok = data;
    });
    return tok;
  };
  useEffect(() => {
    dispatch(getCategoryMaster(["ProfileUpload"]));
    getInitMessages(selectedConsultation.casesheetId);
    onLineRef.current = setInterval(async () => {
      const status = await getUserOnlineStatus(
        clientRTM,
        selectedConsultation.doctorId
      );
      setIsPatientLoggedIn(status);
    }, 1000);
    console.log("testtsetse");
    getRtcToken();
    return () => {
      console.log(agoraRtm, "agoraRtm agoraRtm agoraRtm");
      leave(agoraRtm);
      clearInterval(onLineRef?.current);
      setRtcToken(null);
    };
  }, []);
  console.log(agoraRtm, "agoraRtm message");
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: memberCount.includes(selectedConsultation.doctorId)
        ? theme.palette.success.main
        : isPatLoggedIn
        ? theme.palette.warning.main
        : theme.palette.error.main,
      color: memberCount.includes(selectedConsultation.doctorId)
        ? theme.palette.success.main
        : isPatLoggedIn
        ? theme.palette.warning.main
        : theme.palette.error.main,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  console.log(messages, "messages");
  const SendToChannel = async (data) => {
    console.log(channelAgora);
    if (channelAgora)
      await channelAgora
        .sendMessage({ text: JSON.stringify(data) })
        .then(() => {
          console.log(data, "0987654321");
          setMessages((msg) => [
            ...msg,
            {
              id: data?.id,
              isActive: true,
              message: {
                event: data?.message.event,
                senderId: data?.message.senderId,
                senderName: data?.message.senderName,
                text: data?.message.text,
                fileType: data?.message.fileType,
                time: data?.message.time,
                category: data?.message?.category,
              },
            },
          ]);
          if (!memberCount.includes(selectedConsultation.doctorId)) {
            const msgData = {
              collapse_key: "type_a",

              app: "caretaker",

              title: selectedConsultation.patientName,

              message: data?.message.text,

              userId: selectedConsultation.doctorId,

              channel: selectedConsultation.casesheetId,

              priority: "high",

              data: {
                type: "",

                event: "message",

                channelName: selectedConsultation.casesheetId,

                token: "",

                callerName: "",

                callerRole: "",

                prof: "",

                consultationDate: selectedConsultation.consultationDate,

                consultationId: selectedConsultation.id,

                type: notificationTypeForOnClick.type1,

                prof: selectedUser.profilePicture,
              },

              android: {
                priority: "high",
              },
            };
            dispatch(sendAndSavePushNotificationService(msgData));
            // const temp = !memberCount.includes(selectedConsultation.doctorId);
            // if (!temp.tag) temp.tag = moment().format("DD-MM-YYYYhh:mm:ss");
            // temp.message.push(data?.message.text);
            // setIsOffline(temp);
            // msgData.tag = temp.tag;
            // msgData.message = temp.message.join("\n");
            // const sendM = (msgData) => {
            //   if (memberCount || isPatientInCall) return;
            //   else dispatch(sendAndSavePushNotificationService(msgData));
            // }
            // if (!isVideocall) dispatch(sendAndSavePushNotificationService(msgData));
            // else setTimeout(() => (memberCount || isPatientInCall) && sendM(msgData), 8000);
          }
        });
  };
  const onSend = (msg) => {
    if (msg.length > 0) {
      const data = {
        event: "chat",
        channel: selectedConsultation.casesheetId,
        senderId: selectedConsultation.patientId,
        recieverId: selectedConsultation.doctorId,
        isActive: true,
        message: {
          event: "chat",
          senderName: selectedConsultation.patientName,
          senderId: selectedConsultation.patientId,
          text: msg,
          time: new Date(),
        },
      };
      dispatch(ChatService("post", "", data, undefined, SendToChannel));
      setTypeMessage("");
    }
  };
  const sendDeletedUpdateToReceiver = async (id) => {
    const data = {
      event: "chat",
      channel: selectedConsultation.casesheetId,
      senderId: selectedConsultation.patientId,
      recieverId: selectedConsultation.doctorId,
      message: {
        event: "update",
        senderName: selectedConsultation.patientName,
        senderId: selectedConsultation.patientId,
        text: id,
        time: new Date(),
      },
    };
    if (channelAgora)
      await channelAgora.sendMessage({ text: JSON.stringify(data) });
  };
  // Attachment
  const [open, setOpen] = React.useState(false);
  const {
    handleSubmit,
    setValue,
    control,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      documentName: "",
      documentType: "",
      fileName: "",
      fileType: "",
      isActive: true,
      notes: "",
    },
    resolver: yupResolver(schema),
  });
  console.log(errors, "errors");
  useEffect(() => {
    console.log(watch());
  }, [watch()]);
  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangeFile = async (e, b) => {
    if (e.length > 0) {
      const uploadBody = {
        id: selectedUser.patientId,
        uploadFor: "patient",
        documentType: profileupload.items.find(
          (val) => val.codeName === getValues("documentType")
        ).name,
        fileType: e[0].type,
        apiType: "upload",
      };
      const res = await fileAttachmentUploadURLGenName(e[0], uploadBody, false);
      console.log(res);
      if (res) {
        setValue("fileName", res);
        setValue("fileType", e[0].type);
      }
    } else {
      setValue("fileName", "");
      setValue("fileType", "");
    }
  };
  const onSubmit = async (data) => {
    console.log(data);
    const msgData = {
      event: "chat",
      channel: selectedConsultation.casesheetId,
      senderId: selectedConsultation.patientId,
      recieverId: selectedConsultation.doctorId,
      message: {
        event: "file",
        senderName: selectedConsultation.patientName,
        senderId: selectedConsultation.patientId,
        text: data.fileName,
        time: new Date(),
        fileType: data.fileType,
        category: {
          codeName: data.documentType,
          name: data.documentName,
        },
        categoryNotes: data.notes,
        prof: null,
      },
    };
    console.log(msgData);
    dispatch(ChatService("post", "", msgData, undefined, SendToChannel));
    reset();
    handleClose();
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const disableButtonStatus = ["COMPLETED", "CANCELLED", "MISSED"];
  const connectCall = () => {
    let data = {
      collapse_key: "type_a",
      app: "caretaker",
      title: selectedConsultation?.patientName,
      message: "",
      userId: getConsultationPersonIdForCall(),
      channel: selectedConsultation?.casesheetId + agoraCallChannelPreFix,
      priority: "high",
      data: {
        type:
          selectedConsultation.modeOfConsultation === "VIDEO"
            ? "video"
            : "audio",
        event: "call",
        channelName: selectedConsultation.casesheetId + agoraCallChannelPreFix,
        token: rtcToken,
        callerName: selectedConsultation?.patientName,
        callerId: selectedConsultation?.patientId,
        callerRole: "Patient",
        prof: selectedConsultation?.patientProfile?.profilePicture,
        consultationDate: selectedConsultation.consultationDate,
        consultationId: selectedConsultation.id,
        recipient: getConsultationPersonIdForCall(),
        startTime: moment(),
        startBy: selectedConsultation?.patientName,
      },
      android: {
        priority: "high",
      },
    };
    let callData = {
      data: {
        channelName: selectedConsultation?.casesheetId + agoraCallChannelPreFix,
        token: rtcToken,
        type:
          selectedConsultation.modeOfConsultation === "VIDEO"
            ? "video"
            : "audio",
        callerName: selectedConsultation?.doctorName,
        prof: selectedConsultation?.doctorProfilePicture,
      },
    };
    startCallFun(callData);
    sendPushNotificationService(data);
  };
  const onClickCall = async () => {
    if (
      moment().format("YYYY-MM-DD") ===
        selectedConsultation?.consultationDate &&
      moment(selectedConsultation?.timeSlot, "HH:mm:ss").diff(
        moment(),
        "minutes"
      ) < 15
    ) {
      if (rtcToken) connectCall();
      else {
        const res = await getRtcToken();
        res && setRtcToken(res);
      }
    } else {
      triggerToastWarn(
        "You can make call only before 15min of your consultation"
      );
    }
  };
  return (
    <Container>
      <Box
        sx={{
          py: 5,
        }}
      >
        <Card>
          <CardHeader
            avatar={
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={
                    selectedConsultation?.doctorProfilePicture
                      ? selectedConsultation?.doctorProfilePicture
                      : profileImage
                  }
                />
              </StyledBadge>
            }
            action={
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {!disableButtonStatus.includes(selectedConsultation.status) &&
                  selectedConsultation.modeOfConsultation === "AUDIO" && (
                    <IconButton aria-label="audio" onClick={onClickCall}>
                      <LocalPhoneRoundedIcon />
                    </IconButton>
                  )}
                {!disableButtonStatus.includes(selectedConsultation.status) &&
                  selectedConsultation.modeOfConsultation === "VIDEO" && (
                    <IconButton aria-label="video" onClick={onClickCall}>
                      <VideocamRoundedIcon />
                    </IconButton>
                  )}
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <InfoIcon />
                </ExpandMore>
                {/* <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton> */}
              </Box>
            }
            title={`${selectedConsultation.doctorName}`}
            subheader={
              memberCount.includes(selectedConsultation.doctorId)
                ? "Online"
                : isPatLoggedIn
                ? "Busy"
                : "Offline"
            }
          />
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent sx={{ p: 0 }}>
              <Box>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.info.main,
                    mb: 0,
                    textAlign: "center",
                  }}
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  Consultation Details
                </Typography>
                <Card
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.light,
                    mb: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      backgroundColor: (theme) => theme.palette.primary.light,
                    }}
                  >
                    <Avatar
                      alt={"avatar"}
                      src={
                        selectedUser.profilePicture
                          ? selectedUser.profilePicture
                          : profilePic
                      }
                      sx={{
                        width: 100,
                        height: 100,
                        padding: 2,
                        clipPath: "circle(35%)",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        bgcolor: (theme) => theme.palette.common.white,
                        borderRadius: "10px",
                        m: 2,
                      }}
                    >
                      <CardContent
                        sx={{
                          flex: "1 0 auto",
                          // padding: "16px !important",
                        }}
                      >
                        <Typography
                          variant="button"
                          display="block"
                          color="text.secondary"
                          sx={{
                            fontWeight: "Bold",
                            textTransform: "capitalize",
                          }}
                        >
                          Full Name:{" "}
                          {
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              component="span"
                            >
                              {selectedConsultation.patientName}
                            </Typography>
                          }
                        </Typography>
                        <Typography
                          variant="button"
                          display="block"
                          color="text.secondary"
                          sx={{
                            fontWeight: "Bold",
                            textTransform: "capitalize",
                          }}
                        >
                          DMRD No:{" "}
                          {
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              component="span"
                            >
                              {selectedConsultation.patientId}
                            </Typography>
                          }
                        </Typography>
                        {selectedConsultation.patientProfile.arNumber && (
                          <Typography
                            variant="button"
                            display="block"
                            color="text.secondary"
                            sx={{
                              fontWeight: "Bold",
                              textTransform: "capitalize",
                            }}
                          >
                            AMRD No:{" "}
                            {
                              <Typography
                                variant="button"
                                color="text.secondary"
                                component="span"
                              >
                                {selectedConsultation.patientProfile?.arNumber}
                              </Typography>
                            }
                          </Typography>
                        )}
                        {selectedConsultation.patientId ===
                        primaryUser.patientId ? (
                          <Box>
                            <Chip label="Self" color="primary" size="small" />
                          </Box>
                        ) : selectedUser.relationType ? (
                          <Box>
                            <Chip
                              label={
                                selectedConsultation?.patientProfile
                                  ?.relationType?.name
                              }
                              color="info"
                              size="small"
                            />
                          </Box>
                        ) : null}
                      </CardContent>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </CardContent>
            <Grid
              container
              sx={{
                backgroundColor: (theme) => theme.palette.primary.light,
              }}
            >
              <Grid item xs={6}>
                <CardContent>
                  <Grid
                    container
                    sx={{
                      backgroundColor: (theme) => theme.palette.common.white,
                      borderRadius: "10px",
                      p: 2,
                    }}
                  >
                    <Grid item xs={6}>
                      <Typography
                        variant="button"
                        display="block"
                        color="text.secondary"
                        sx={{ fontWeight: "Bold", textTransform: "capitalize" }}
                      >
                        Doctor Name:{" "}
                        {
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="span"
                          >
                            {selectedConsultation.doctorName}
                          </Typography>
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="button"
                        display="block"
                        color="text.secondary"
                        sx={{ fontWeight: "Bold", textTransform: "capitalize" }}
                      >
                        Specialization:{" "}
                        {
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="span"
                          >
                            {selectedConsultation?.speciality?.name}
                          </Typography>
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="button"
                        display="block"
                        color="text.secondary"
                        sx={{ fontWeight: "Bold", textTransform: "capitalize" }}
                      >
                        Consultation Date:{" "}
                        {
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="span"
                          >
                            {moment(
                              selectedConsultation.consultationDate,
                              "YYYY-MM-DD"
                            ).format("DD-MMM-YYYY")}
                          </Typography>
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="button"
                        display="block"
                        color="text.secondary"
                        sx={{ fontWeight: "Bold", textTransform: "capitalize" }}
                      >
                        Consultation Time:{" "}
                        {
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="span"
                          >
                            {moment(
                              selectedConsultation.timeSlot,
                              "HH:mm:ss"
                            ).format("hh:mm A")}
                          </Typography>
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="button"
                        display="block"
                        color="text.secondary"
                        sx={{ fontWeight: "Bold", textTransform: "capitalize" }}
                      >
                        Consultation Mode:{" "}
                        {
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="span"
                          >
                            {selectedConsultation.modeOfConsultation}
                          </Typography>
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {selectedConsultation.modeOfConsultation === "VIDEO" ? (
                        <Stack direction="row" spacing={1}>
                          <Avatar
                            sx={{
                              width: 24,
                              height: 24,
                              bgcolor: (theme) => theme.palette.error.light,
                            }}
                          >
                            <VideocamRoundedIcon
                              sx={{ width: 15, height: 15 }}
                            />
                          </Avatar>
                          <Avatar
                            sx={{
                              width: 24,
                              height: 24,
                              bgcolor: (theme) => theme.palette.warning.light,
                            }}
                          >
                            <ChatRoundedIcon sx={{ width: 15, height: 15 }} />
                          </Avatar>
                        </Stack>
                      ) : selectedConsultation.modeOfConsultation ===
                        "AUDIO" ? (
                        <Stack direction="row" spacing={1}>
                          <Avatar
                            sx={{
                              width: 24,
                              height: 24,
                              bgcolor: (theme) => theme.palette.info.light,
                            }}
                          >
                            <LocalPhoneRoundedIcon
                              sx={{ width: 15, height: 15 }}
                            />
                          </Avatar>
                          <Avatar
                            sx={{
                              width: 24,
                              height: 24,
                              bgcolor: (theme) => theme.palette.warning.light,
                            }}
                          >
                            <ChatRoundedIcon sx={{ width: 15, height: 15 }} />
                          </Avatar>
                        </Stack>
                      ) : selectedConsultation.modeOfConsultation === "CHAT" ? (
                        <Stack direction="row" spacing={1}>
                          <Avatar
                            sx={{
                              width: 24,
                              height: 24,
                              bgcolor: (theme) => theme.palette.warning.light,
                            }}
                          >
                            <ChatRoundedIcon sx={{ width: 15, height: 15 }} />
                          </Avatar>
                        </Stack>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="button"
                        display="block"
                        color="text.secondary"
                        sx={{ fontWeight: "Bold", textTransform: "capitalize" }}
                      >
                        Address:{" "}
                        {
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="span"
                          >
                            {`${
                              selectedConsultation.patientAddress?.area || ""
                            } ${
                              selectedConsultation.patientAddress?.street || ""
                            } ${
                              selectedConsultation.patientAddress?.city || ""
                            } ${
                              selectedConsultation.patientAddress?.state || ""
                            } ${
                              selectedConsultation.patientAddress?.pincode || ""
                            }`}
                          </Typography>
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="button"
                        display="block"
                        color="text.secondary"
                        sx={{ fontWeight: "Bold", textTransform: "capitalize" }}
                      >
                        Amount:{" "}
                        {
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="span"
                          >
                            {selectedConsultation.consultationAmount
                              ? `Rs. ${selectedConsultation.consultationAmount}`
                              : "Free"}
                          </Typography>
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="button"
                        display="block"
                        color="text.secondary"
                        sx={{ fontWeight: "Bold", textTransform: "capitalize" }}
                      >
                        Booking Id:{" "}
                        {
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="span"
                          >
                            {selectedConsultation?.orderId?.consultationId}
                          </Typography>
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="button"
                        display="block"
                        color="text.secondary"
                        sx={{ fontWeight: "Bold", textTransform: "capitalize" }}
                      >
                        Order No:{" "}
                        {
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="span"
                          >
                            {selectedConsultation?.orderId?.orderId}
                          </Typography>
                        }
                      </Typography>
                    </Grid>
                    {/* {!(
                consultationCouponCode &&
                Object.keys(consultationCouponCode).length === 0
              ) ? (
                <Grid item xs={6}>
                  <Typography
                    variant="button"
                    display="block"
                    color="success"
                    sx={{
                      fontWeight: "Bold",
                      textTransform: "capitalize",
                      color: (theme) => theme.palette.success,
                    }}
                  >
                    Discount:{" "}
                    {
                      <Typography
                        variant="body2"
                        sx={{
                          color: (theme) => theme.palette.success.main,
                        }}
                        component="span"
                      >
                        {`Rs. ${consultationCouponCode.fixAmount}`}
                      </Typography>
                    }
                  </Typography>
                </Grid>
              ) : null} */}
                  </Grid>
                </CardContent>
              </Grid>
              <Grid item xs={6}>
                <CardContent>
                  <Box
                    sx={{
                      backgroundColor: (theme) => theme.palette.common.white,
                      borderRadius: "10px",
                      p: 2,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Payment Summary
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Consultation fees [
                        {selectedConsultation?.modeOfConsultation}]
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {`Rs. ${Number(
                          selectedConsultation?.orderId?.totalAmount
                        ).toFixed(2)}`}
                      </Typography>
                      {/* {consultationOrderDetails.couponDiscount ? (
                      <>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Coupon Discount Cost
                        </Typography>
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {`Rs. ${Number(
                            consultationOrderDetails.couponDiscount
                          ).toFixed(2)}`}
                        </Typography>
                      </>
                    ) : null} */}
                    </Box>
                    {/* {consultationOrderDetails.couponDiscount ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Coupon Discount Cost
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {`Rs. ${Number(
                          consultationOrderDetails.couponDiscount
                        ).toFixed(2)}`}
                      </Typography>
                    </Box>
                  ) : null} */}
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="button"
                        display="block"
                        gutterBottom
                        fontWeight="bold"
                      >
                        Payment Cost
                      </Typography>
                      <Typography
                        variant="button"
                        display="block"
                        gutterBottom
                        fontWeight="bold"
                      >
                        {`Rs. ${Number(
                          selectedConsultation?.orderId?.netAmount
                        ).toFixed(2)}`}
                      </Typography>
                    </Box>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="button"
                        display="block"
                        gutterBottom
                        fontWeight="bold"
                      >
                        Current Status
                      </Typography>
                      <Typography
                        variant="button"
                        display="block"
                        gutterBottom
                        fontWeight="bold"
                      >
                        {selectedConsultation?.status}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Grid>
            </Grid>
          </Collapse>
          <CardContent
            ref={scrollRef}
            sx={{
              height: "65vh",
              bgcolor: (theme) => theme.palette.grey[300],
              overflow: "auto",
            }}
          >
            {/* <ScrollToBottom> */}
            {messages &&
              Array.isArray(messages) &&
              messages.length > 0 &&
              messages.map((item, key) =>
                item.message.event?.toLowerCase() === "chat" ||
                item.message.event?.toLowerCase() === "file" ? (
                  <Box>
                    <Box
                      sx={{
                        textAlign: "center",
                        my: 2,
                      }}
                    >
                      {key === 0 ||
                      moment(messages[key - 1]?.message.time).format(
                        "DD-MM-YYYY"
                      ) !==
                        moment(messages[key].message.time).format(
                          "DD-MM-YYYY"
                        ) ? (
                        <Box>
                          <span
                            style={{
                              background: "#F0FFFF",
                              borderRadius: 8,
                              padding: "0.5rem",
                            }}
                          >
                            {moment(new Date()).format("DD-MM-YYYY") ===
                            moment(messages[key].message.time).format(
                              "DD-MM-YYYY"
                            )
                              ? "Today"
                              : moment(
                                  new Date().setDate(new Date().getDate() - 1)
                                ).format("DD-MM-YYYY") ===
                                moment(messages[key].message.time).format(
                                  "DD-MM-YYYY"
                                )
                              ? "Yesterday"
                              : moment(messages[key].message.time).format(
                                  "DD-MM-YYYY"
                                )}
                          </span>
                        </Box>
                      ) : null}
                    </Box>
                    {selectedConsultation.patientId ===
                    item.message.senderId ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <MyMsgComponent
                            message={item}
                            sendDeletedUpdateToReceiver={
                              sendDeletedUpdateToReceiver
                            }
                          />
                        </Box>
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <ReceivedMsgComponent
                          message={item}
                          setReceivedDeletedMsgId={setReceivedDeletedMsgId}
                          receivedDeletedMsgId={receivedDeletedMsgId}
                        />
                      </Box>
                    )}
                    {/* <div ref={scrollRef} className="pt-0"></div> */}
                  </Box>
                ) : null
              )}
            {/* </ScrollToBottom> */}
          </CardContent>
          <CardActions disableSpacing>
            <IconButton
              aria-label="add to favorites"
              disabled={
                selectedConsultation.status.includes("COMPLETED") ||
                selectedConsultation.status.includes("CANCELLED") ||
                selectedConsultation.status.includes("MISSED")
              }
              onClick={handleClickOpen}
            >
              <AttachFileIcon />
            </IconButton>
            <TextField
              id="outlined-basic"
              label="Send Message..."
              variant="outlined"
              fullWidth
              disabled={
                selectedConsultation.status.includes("COMPLETED") ||
                selectedConsultation.status.includes("CANCELLED") ||
                selectedConsultation.status.includes("MISSED")
              }
              value={typeMessage}
              onChange={(e) => {
                if (e) {
                  setTypeMessage(e.target.value);
                } else {
                  setTypeMessage("");
                }
              }}
              size="small"
            />
            <Avatar
              onClick={() => {
                if (
                  !selectedConsultation.status.includes("COMPLETED") ||
                  !selectedConsultation.status.includes("CANCELLED") ||
                  !selectedConsultation.status.includes("MISSED")
                ) {
                  onSend(typeMessage);
                }
              }}
              // disabled={}
              sx={{
                bgcolor: (theme) => theme.palette.success.light,
                mx: 2,
                cursor: "pointer",
                ":hover": {
                  boxShadow: (theme) => theme.shadows[10],
                },
              }}
            >
              <TelegramIcon />
            </Avatar>
          </CardActions>
        </Card>
        <Dialog
          open={open}
          fullWidth
          scroll="paper"
          maxWidth="lg"
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle
              style={{ cursor: "move", fontSize: fontData.small5 }}
              id="draggable-dialog-title"
            >
              Upload
            </DialogTitle>
            <DialogContent dividers>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Controller
                      control={control}
                      name="documentType"
                      render={({ field }) => (
                        <FormControl fullWidth margin="normal" required>
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            size="small"
                          >
                            Category
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            size="small"
                            error={Object.prototype.hasOwnProperty.call(
                              errors,
                              "documentType"
                            )}
                            value={getValues("documentType")}
                            label="Category"
                            onChange={(e) => {
                              field.onChange(e.target.value);
                              if (e) {
                                setValue(
                                  "documentName",
                                  profileupload.items.find(
                                    (val) => val.codeName === e.target.value
                                  ).name
                                );
                                return setValue("documentType", e.target.value);
                              }
                            }}
                          >
                            {profileupload &&
                              profileupload.items &&
                              Array.isArray(profileupload.items) &&
                              profileupload.items.length > 0 &&
                              profileupload.items.map((val, i) => (
                                <MenuItem key={i} value={val.codeName}>
                                  {val.name}
                                </MenuItem>
                              ))}
                          </Select>
                          {Object.prototype.hasOwnProperty.call(
                            errors,
                            "documentType"
                          ) ? (
                            <FormHelperText
                              error
                              sx={{ color: (theme) => theme.palette.error }}
                            >
                              {errors.documentType.message}
                            </FormHelperText>
                          ) : null}
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      control={control}
                      name="notes"
                      render={({ field }) => (
                        <TextField
                          error={Object.prototype.hasOwnProperty.call(
                            errors,
                            "notes"
                          )}
                          margin="normal"
                          required
                          value={getValues("notes")}
                          size="small"
                          fullWidth
                          label="Notes"
                          name="notes"
                          autoFocus
                          helperText={
                            Object.prototype.hasOwnProperty.call(
                              errors,
                              "notes"
                            )
                              ? errors.notes.message
                              : ""
                          }
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            if (e) return setValue("notes", e.target.value);
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {getValues("documentType") && (
                    <Grid item xs={12}>
                      <DropzoneArea
                        onChange={handleChangeFile}
                        dropzoneText={"Drag & drop/Click to upload"}
                        filesLimit={1}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions sx={{ mr: 2 }}>
              <Button
                autoFocus
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  fontSize: fontData.small1,
                  backgroundColor: "red",
                  color: "#fff",
                  ":hover": {
                    backgroundColor: "red",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              {getValues("fileName") && (
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    textTransform: "capitalize",
                    fontSize: fontData.small1,
                  }}
                  color="info"
                >
                  Upload
                </Button>
              )}
            </DialogActions>
          </Box>
        </Dialog>
      </Box>
    </Container>
  );
};

export default VideoAudioChat;
