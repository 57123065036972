const initialState = {
  pharmacyoptions: 0,
  prescriptions: [],
  onePrescriptionData: {},
  prescriptionItems: {},
  pharmacyRequest: [],
  requestInfo: [],
  pharmacyMyOrder: [],
  PatientCart: {},
  TrackandOrder: 0,
  CartData: [],
  pharmacyOrderTrack: [],
  pharmacyPromoCode: {},
  pharmacyLoad: true,
  pharmacyOrderSuccessPopup: {},
};
const pharmacyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PHARMACY_OPTIONS":
      return {
        ...state,
        pharmacyoptions: action.payload,
      };
    case "PHARMACY_ORDER_SUCCESSPOPUP":
      return {
        ...state,
        pharmacyOrderSuccessPopup: action.payload,
      };
    case "PHARMACY_PRESCIPTIONS":
      return {
        ...state,
        prescriptions: action.payload,
      };
    case "PHARMACY_REQUEST":
      return {
        ...state,
        pharmacyRequest: action.payload,
      };
    case "PHARMACY_MY_ORDERS":
      return {
        ...state,
        pharmacyMyOrder: action.payload,
      };
    case "PHARMACY_ORDER_TRACKING":
      return {
        ...state,
        pharmacyOrderTrack: action.payload,
      };
    case "PHARMACY_TRACK_AND_ORDER_TAP":
      return {
        ...state,
        TrackandOrder: action.payload,
      };
    case "GET_PHARMACY_ADD_CART":
      return {
        ...state,
        CartData: action.payload,
      };
    case "GET_PATIENT_CART":
      return {
        ...state,
        PatientCart: action.payload,
      };
    case "PHARMACY_PROMO_CODE":
      return {
        ...state,
        pharmacyPromoCode: action.payload,
      };
    case "GET_PRESCRIPTIOPN_BYINFO":
      return {
        ...state,
        onePrescriptionData: action.payload,
      };
    case "GET_PRESCRIPTION_ITEMS":
      return {
        ...state,
        prescriptionItems: action.payload,
      };
    case "GET_REQUEST_INFO":
      return {
        ...state,
        requestInfo: action.payload,
      };
    case "PHARMACY_LOAD":
      return {
        ...state,
        pharmacyLoad: action.payload,
      };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};
export default pharmacyReducer;
