import { Box, Typography } from "@mui/material";
import React from "react";
import {
  FilterAltOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import "./sortbydate.css";

function SortByDate(props) {
  const {
    sortData,
    setPageNumber,
    setItemPerPage,
    setSortData,
    pageNum,
    itemNum,
    // sortNum,
  } = props;
  return (
    <div>
      {" "}
      <div className="sort-data">
        <div
          className="sort-data-1"
          onClick={() => {
            if (sortData === -1) {
              setSortData(1);
              setPageNumber(pageNum);
              setItemPerPage(itemNum);
            } else {
              setSortData(-1);
              setPageNumber(pageNum);
              setItemPerPage(itemNum);
            }
          }}
        >
          {" "}
          <FilterAltOutlined className="icon" />
          <Typography className="fontsize">Sort by date</Typography>
          {sortData === -1 ? (
            <KeyboardArrowDownOutlined className="icon" />
          ) : (
            <KeyboardArrowUpOutlined className="icon" />
          )}
        </div>
      </div>
    </div>
  );
}

export default SortByDate;
