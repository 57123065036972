import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { red } from '@mui/material/colors';
import { Button } from '@mui/material';
export default function IncomingCallPopup({ data, onAnswer, onDecline }) {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card>
            <CardHeader
                avatar={
                    data?.data?.prof ?
                        <Avatar src={data?.data?.prof}/>
                        :
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            {data?.data?.callerName ? data?.data?.callerName[0] : 'D'}
                        </Avatar>
                }
                title={data?.data?.callerName || 'Diahome'}
                subheader="Incoming call"
                titleTypographyProps={{ textAlign: 'left' }}
                subheaderTypographyProps={{ textAlign: 'left' }}
            />
            <CardActions style={{ justifyContent: 'center', background: '#1aedc921', padding: 0 }} >
                <Button onClick={onAnswer} color='success' size="small">Answer</Button>
                <Button onClick={onDecline} color='error' size="small">Decline</Button>
            </CardActions>
        </Card>
    );
}
