import moment from "moment";
import React, { useState, useContext, useEffect } from "react";
import { agoraCallChannelPreFix, notificationType } from "../../utils/utils";
import AgoraUIKit, { layout } from "./../../components/agora/index";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../App";
import { sendPushNotificationService } from "../../services/service";

const VideoAudioCall = ({ notification, setNotification, sendMessage, selectedCallView, startCall, setStartCall }) => {
  const { rtmToken, clientRTM } = useContext(ThemeContext);
  const { selectedConsultation } = useSelector(
    (state) => state.consultationReducer
  );
  const { selectedUser } = useSelector((state) => state.usersReducer);
  const [callType, setCallType] = useState(false);
  const [isError, setIsError] = useState(false);
  const [callStarted, setCallStarted] = useState(false);
  const [startBy, setStartBy] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [memberCount, setMemberCount] = useState(0);
  const [rtcProps, setRtcProps] = useState({
    appId: "2a152b3f32f846aeb15cfb04cc995aa5",
    token: "",
    channel: "",
    //  role: "audience",
    //  callActive: true,
    // enableVideo: false,
    // enableAudio: true,
    layout: layout.pin,
    audioCall: false,
  });
  useEffect(() => {
    if (notification && startCall) {
      console.log(notification, startCall, 'in');
      setStartBy(notification.data.startBy);
      setStartTime(notification.data.startTime)
      if (notification?.data?.type === "audio")
        setRtcProps({
          ...rtcProps,
          token: notification.data.token,
          channel: notification.data.channelName,
          audioCall: true,
        })
      else if (notification?.data?.type === "video")
        setRtcProps({
          ...rtcProps,
          token: notification.data.token,
          channel: notification.data.channelName,
          audioCall: false,
        })
      // else if (!notification && agoraCallToken?.data && callType) {
      //   setRtcProps({
      //     ...rtcProps,
      //     token: agoraCallToken?.data,
      //     channel: patientDetails.casesheetId + agoraCallChannelPreFix,
      //     audioCall: callType?.video === false ? true : false,
      //   })
      // }
    }
  }, [notification, startCall])
  const styles = {
    container: {
      // width: "355px",
      // height: "605px",
      width: "100%",
      height: "90%",
      display: "flex",
      flex: 1,
      backgroundColor: "#007bff22",
    },
    // container: { width: '100vw', height: '100vh', display: 'flex', flex: 1, backgroundColor: '#007bff22' },
    heading: { textAlign: "center", marginBottom: 0 },
    videoContainer: { display: "flex", flexDirection: "column", flex: 1 },
    nav: { display: "flex", justifyContent: "space-around" },
    btn: {
      backgroundColor: "#007bff",
      cursor: "pointer",
      borderRadius: 5,
      padding: "4px 8px",
      color: "#ffffff",
      fontSize: 20,
    },
    input: { display: "flex", height: 24, alignSelf: "center" },
  };
  const resetRtcProps = () => {
    setRtcProps({
      ...rtcProps,
      token: "",
      channel: "",
      audioCall: false,
    });
  };
  const getConsultationPersonIdForCall = () => {
    if (selectedConsultation?.paId?.length) return selectedConsultation?.paId[selectedConsultation?.paId?.length - 1]?.empId
    else return selectedConsultation?.doctorId
  }
  const leave = () => {
    try {
      // if (localStream) {
      //   // Closes the local stream. This de-allocates the resources and turns off the camera light
      //   localStream.close();
      //   // unpublish the stream from the client
      //   agoraClient.unpublish(localStream);
      // }
      // agoraClient.leave();
      // clearTimeout(intervalRef.current);
      resetRtcProps();
      setCallType(null);
      setCallStarted(false);
      setNotification(null);
      setIsError(false);
      setStartBy(null);
      setStartTime(null);
      setStartCall(false);
      //   dispatch(addMinimizeVideoCall(false))
      //   dispatch({
      //     type: CARETAKERDATAS,
      //     payload: false,
      //     stateName: stateNames.isPatientInCall,
      //   });
      //   dispatch({
      //     type: CARETAKERDATAS,
      //     payload: false,
      //     stateName: stateNames.callerDetail,
      //   });
      //   setTimeout(
      //     () =>
      //       dispatch({
      //         type: CARETAKERDATAS,
      //         payload: false,
      //         stateName: stateNames.isVideoCall,
      //       }),
      //     1000
      //   );
      // dispatch({ type: CARETAKERDATAS, payload: null, stateName: stateNames.callType });
      if (callStarted && memberCount === 2) {
        const end = moment();
        const diff = moment.duration(end.diff(startTime));
        const data = {
          event: "chat",
          channel: notification
            ? notification?.data?.channelName?.replace(
              agoraCallChannelPreFix,
              ""
            )
            : selectedConsultation?.casesheetId,
          senderId: notification?.recipient || selectedUser.patientId,
          recieverId: notification?.data?.callerId,
          message: {
            event: "alert",
            senderId: notification?.recipient || selectedUser.patientId,
            senderName: selectedUser?.firstName + " " + selectedUser?.lastName,
            text: `Call Ended By ${selectedUser?.firstName} ${selectedUser?.lastName
              } on ${moment().format("LLLL")}(${diff._data.hours ? diff._data.hours + "h" : ""
              }${diff._data.minutes ? diff._data.minutes + "m" : ""}${diff._data.seconds ? diff._data.seconds + "s" : ""
              })`,
            time: new Date(),
          },
        };
        sendMessage(data);
      }
      if (memberCount === 1) {
        const data2 = {
          collapse_key: 'type_a',
          app: 'caretaker',
          userId: getConsultationPersonIdForCall,
          priority: 'high',
          data: {
            event: notificationType.type5,
            callerId: selectedConsultation?.patientId,
            callerRole: 'Patient',
          },
          android: {
            priority: 'high',
          },
        }
        sendPushNotificationService(data2)
      }

      // dispatch({
      //   type: CARETAKERDATAS,
      //   payload: undefined,
      //   stateName: stateNames.channel,
      // });
      // leave the channel
    } catch {
      console.log("wdghtywgvytvytd");
    }
  };
  const callbacks = {
    EndCall: () => leave(),
    RtcMemberCount: (count, list) => {
      setMemberCount(count);
      console.log(count, callStarted, "countcountcountcount");
    },
    "connection-state-change": (curState, revState, reason) => {
      if (curState === "CONNECTED" && notification) {
        const data = {
          event: "chat",
          channel: notification?.data.channelName?.replace(
            agoraCallChannelPreFix,
            ""
          ),
          senderId: notification?.recipient || selectedUser.patientId,
          recieverId: notification?.data?.callerId,
          message: {
            event: "alert",
            senderId: notification?.recipient || selectedUser.patientId,
            senderName: notification?.recipientName,
            text: `${notification.recipientName ||
              `${selectedUser.firstName} ${selectedUser.lastName}`
              } joined on ${moment().format("hh:mmA")}`,
            time: new Date(),
          },
        };
        setTimeout(() => sendMessage(data), 1000);
      }
    },
  };
  const buttonSize = 35;
  const buttonSize2 = 20;
  return (
    <div style={styles.container}>
      <div style={styles.videoContainer}>
        {startCall && rtcProps?.channel ? (
          <AgoraUIKit
            rtcProps={rtcProps}
            callbacks={callbacks}
            rtmProps={{
              username:
                notification?.recipientName ||
                `${selectedUser.firstName} ${selectedUser.lastName}`,
              uid: notification?.recipient || selectedUser.patientId,
              displayUsername: true,
              token: rtmToken,
              customRTMClient: clientRTM,
              // wait until client ready
              isCustomRTMClient: true,
            }}
            // customProps={{
            //   mobileView: true,
            //   additionalLocalControls: <TemporaryDrawer />
            // }}
            styleProps={{
              localBtnContainer: {
                borderEndStartRadius: 10,
                borderEndEndRadius: 10,
                height: selectedCallView === 2 ? 30 : '',
                background: '#00b6be'
              },
              localBtnStyles: {
                muteLocalVideo: {
                  width: selectedCallView === 2 ? buttonSize2 : buttonSize,
                  height: selectedCallView === 2 ? buttonSize2 : buttonSize,
                  borderColor: selectedCallView === 2 ? '#00b6be' : '#fff'
                },
                muteLocalAudio: {
                  width: selectedCallView === 2 ? buttonSize2 : buttonSize,
                  height: selectedCallView === 2 ? buttonSize2 : buttonSize,
                  borderColor: selectedCallView === 2 ? '#00b6be' : '#fff'
                },
                endCall: {
                  width: selectedCallView === 2 ? buttonSize2 : buttonSize,
                  height: selectedCallView === 2 ? buttonSize2 : buttonSize,
                  backgroundColor: "#ef5588",
                  borderColor: "#f00",
                },
              },
              // call remote user buttons
              BtnTemplateStyles: {
                width: 25,
                height: 25,
              },
              iconSize: 14,
              // call remote user buttons
              scrollViewContainer: {
                maxWidth: "357px",
              },
              minViewContainer: {
                // for mobile design
                minWidth: "10vw",
              },
              mobileDesign: true,
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default VideoAudioCall;
