import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import under from "../../assets/images/users/under.jpg";

function UnderMaintance() {
  return (
    <Container sx={{ p: 4 }}>
      <Box>
        <Card>
          <CardContent>
            <CardMedia
              component="img"
              height="500"
              image={under}
              alt="Paella dish"
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 4,
              }}
            >
              <Typography variant="h2" component={"h"}>
                {" "}
                {/* Under Construction */}
                UNDER CONSTRUCTION
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

export default UnderMaintance;
