import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getToken } from "./../utils/utils";

// const headers = () => {
//   return {
//     applicationLabel: 'HRMS-Portal',
//     correlationId: uuidv4(),
//     'Content-Type': 'application/json',
//     Authorization: authVerify(),
//   };
// };
axios.interceptors.request.use((request) => {
  // add auth header with jwt if account is logged in and request is to the api url
  // const account = accountService.accountValue;
  // const isLoggedIn = account?.token;
  // const isApiUrl = request.url.startsWith(process.env.REACT_APP_API_URL);
  console.log(getToken(), "getToken()");
  const token =
    getToken() === null || getToken() === undefined || getToken() === ""
      ? undefined
      : `Bearer ${getToken()}`;
  console.log(token, "token");
  const basicURL = "storage.googleapis.com";
  if (request.url.includes(basicURL)) {
    console.log(request.url, "urls in");
    return request;
  } else {
    if (token !== undefined) {
      request.headers.common.Authorization = token;
    }
    request.headers.common.applicationLabel = "Patient-Web";
    request.headers.common.correlationId = uuidv4();
    console.log(request, "url out");

    return request;
  }
});
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // if (response.data.code === 401) return logout(store.dispatch);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error.response, "error.response");
    // if(error.response.data.code === 401)  {
    //  store.dispatch({ type: CARETAKERDATAS, payload: false, stateName: stateNames.logoutModal });
    //   cookies.remove('token', { path: '/' });
    //   localStorage.removeItem('email');
    //   localStorage.removeItem('empId');
    //   localStorage.removeItem('role');
    //   localStorage.removeItem('username');
    //   localStorage.removeItem('userName');
    //   localStorage.removeItem('apiLockId');
    //   window.location.reload();
    // }

    return Promise.reject(error);
  }
);
export default axios;
