const initialState = {
  primaryUser: {},
  selectedUser: {},
  selectedProfileMenuTab: 0,
  selectedUserAddress: [],
  selectedAddress: {},
  userProfileTab: 0,
  userAddressTab: 0,
  userConsultationTab: 0,
  userPaymentTab: 0,
  userFollowupTab: 1,
  userHealthRecordTab: 0,
  userVitalsDetails: [],
  bookDoctor: {},
  patientMessage: [],
  channelA: null,
  channelB: null,
  showChat: false,
  showSupport: true,
  faqTab: 0,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FAQ_TAB":
      return {
        ...state,
        faqTab: action.payload,
      };
    case "SHOW_CHAT":
      return {
        ...state,
        showChat: action.payload,
      };
    case "SHOW_SUPPORT":
      return {
        ...state,
        showSupport: action.payload,
      };
    case "BOOK_DOCTOR_DATA":
      return {
        ...state,
        bookDoctor: action.payload,
      };
    case "PATIENT_MESSAGE":
      return {
        ...state,
        patientMessage: action.payload,
      };
    case "PATIENT_CHAT":
      return {
        ...state,
        patientChat: action.payload,
      };
    case "USERS_PRIMARY_USER":
      return {
        ...state,
        primaryUser: action.payload,
      };
    case "USERS_SELECTED_USER":
      return {
        ...state,
        selectedUser: action.payload,
      };
    case "CHANNELA":
      return {
        ...state,
        channelA: action.payload,
      };
    case "CHANNELB":
      return {
        ...state,
        channelB: action.payload,
      };
    case "PROFILE_MENU_TAB":
      return {
        ...state,
        selectedProfileMenuTab: action.payload,
      };
    case "USER_PROFILE_TAB":
      return {
        ...state,
        userProfileTab: action.payload,
      };
    case "USERS_SELECTED_USER_ADDRESS":
      return {
        ...state,
        selectedUserAddress: action.payload,
      };
    case "USERS_SELECTED_ADDRESS":
      return {
        ...state,
        selectedAddress: action.payload,
      };
    case "USERS_ADDRESS_TAB":
      return {
        ...state,
        userAddressTab: action.payload,
      };
    case "USERS_CONSULTATION_TAB":
      return {
        ...state,
        userConsultationTab: action.payload,
      };
    case "USERS_PAYMENT_TAB":
      return {
        ...state,
        userPaymentTab: action.payload,
      };
    case "USERS_FOLLOWUP_TAB":
      return {
        ...state,
        userFollowupTab: action.payload,
      };
    case "USERS_HEALTHRECORDS_TAB":
      return {
        ...state,
        userHealthRecordTab: action.payload,
      };
    case "USERS_VITALS_DETAILS":
      return {
        ...state,
        userVitalsDetails: action.payload,
      };
    case "RESET_STATE":
      return initialState;
    default:
      return state;
  }
};

export default usersReducer;
