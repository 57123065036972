import axiosInterceptors from "./interceptorAxios";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import { saveAs } from "file-saver";

const baseURL =
  process.env.REACT_APP_BASE_URL + "/diahome/v1/api/patient-service";
// const triggerToastSuccess = (message) => {
//     toast.success(message, {
//       position: 'top-center',
//       autoClose: 3000,
//       hideProgressBar: true,
//       closeOnClick: true,
//       pauseOnHover: true,
//       draggable: true,
//       progress: 0,
//     });
//   };
export const triggerToastSuccess = (message) => {
  toast.success(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const triggerToastError = (message) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const triggerToastWarn = (message) => {
  toast.warn(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
  });
};
export const errormsg = (error) => {
  console.log(error, "error");
  console.log(error.response, "error response");

  if (error && error.response?.status === 417) {
    return triggerToastError(`${error.response?.data?.message}`);
  }
  if (error && error.response?.status === 404) {
    triggerToastError(`Not found`);
  } else if (error && error.response?.status === 405) {
    return triggerToastError(`${error.response?.data?.message}`);
  } else if (error && error.response?.status === 401) {
    console.log(error.response);
    const err = error.response.data.errors;
    // eslint-disable-next-line array-callback-return
    err.map((value) => {
      triggerToastError(`${value.message}`);
    });

    // <Redirect to="/login" />;
  } else if (error && error.response?.status >= 500) {
    triggerToastError(`Server Error`);
  } else if (
    error &&
    error.response &&
    error.response.data.errors?.length > 0
  ) {
    const err = error.response.data.errors;
    console.log(err, "err");
    for (let error of err) {
      if (error.message) {
        triggerToastError(`${error.message}`);
      } else {
        triggerToastError(`${error}`);
      }
    }
  } else {
    triggerToastError(`${error && error.response && error.response.message}`);
  }
};
export const loadingShow = (dispatch, type) =>
  dispatch({ type: "LOADING_SCREEN", payload: type });
export const loading = (type) => (dispatch) => {
  dispatch({ type: "LOADING", payload: type });
};
export const sendOtpToMobile = (d) => async (dispatch, getState) => {
  try {
    loadingShow(dispatch, true);
    const data = {
      mobileNumber: d,
    };
    const config = {
      method: "post",
      url: `${baseURL}/profiles/login/otp`,
      data: data,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        dispatch({
          type: "LOGIN_PATIENT_ID",
          payload: response.data.data.patientId,
        });
        dispatch({ type: "LOGIN_TYPE", payload: response.data.data.type });
        loadingShow(dispatch, false);
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
      });
  } catch (error) {
    loadingShow(dispatch, false);
  }
};
export const refreshFirebaseTokenService =
  (d) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "post",
      url: `${baseURL}/notifications/token/refresh/`,
      data: d,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        loadingShow(dispatch, false);
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
      });
  };
export const verifyOtpFromMobile = (d, nav) => async (dispatch, getState) => {
  loadingShow(dispatch, false);
  const data = {
    patientId: getState().loginReducer.patientId,
    otp: getState().loginReducer.verfyOtp,
  };
  const config = {
    method: "post",
    url: `${baseURL}/profiles/login/otp/verify?type=${
      getState().loginReducer.loginType
    }`,
    data: data,
  };

  return await axiosInterceptors(config)
    .then(async (response) => {
      console.log(response);
      loadingShow(dispatch, false);
      if (getState().loginReducer.loginType === "register") {
        dispatch({ type: "LOGIN_STEP", payload: 3 });
      } else {
        dispatch({ type: "USER_LOGIN", payload: true });
        localStorage.setItem("accessToken", response.data.data.accessToken);
        localStorage.setItem("email", response.data.data.email);
        localStorage.setItem("roles", response.data.data.roles);
        localStorage.setItem("username", response.data.data.username);
        await dispatch(getProfileInfo());
        dispatch({ type: "LOGIN_STEP", payload: 1 });
        nav("/");
      }
      loadingShow(dispatch, false);
    })
    .catch(function (error) {
      console.log(error, "error");
      errormsg(error);
      dispatch({ type: "LOGIN_STEP", payload: 2 });
      loadingShow(dispatch, false);
    });
};
export const createProfileService = (d, nav) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "post",
    url: `${baseURL}/profiles/create`,
    data: d,
  };

  return await axiosInterceptors(config)
    .then(async (response) => {
      console.log(response);
      if (response.status === 201) {
        dispatch({ type: "USER_LOGIN", payload: true });
        localStorage.setItem("accessToken", response.data.data.accessToken);
        localStorage.setItem("email", response.data.data.email);
        localStorage.setItem("roles", response.data.data.roles);
        localStorage.setItem("username", response.data.data.username);
        dispatch({ type: "LOGIN_STEP", payload: 1 });
        await dispatch(getProfileInfo());
        nav("/");
      } else {
        dispatch({ type: "LOGIN_STEP", payload: 3 });
      }
      loadingShow(dispatch, false);
    })
    .catch(function (error) {
      console.log(error, "error");
      errormsg(error);
      loadingShow(dispatch, false);
    });
};
export const updateProfileService = (d, nav) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "PUT",
    url: `${baseURL}/profiles/update`,
    data: d,
  };

  return await axiosInterceptors(config)
    .then(async (response) => {
      console.log(response);
      if (response.status === 200) {
        await dispatch(getProfileInfo(true));
        dispatch({
          type: "USERS_SELECTED_USER",
          payload: response.data.data,
        });
        triggerToastSuccess("Profile Updated Successfully");
      } else {
        dispatch({ type: "LOGIN_STEP", payload: 3 });
      }
      loadingShow(dispatch, false);
    })
    .catch(function (error) {
      console.log(error, "error");
      errormsg(error);
      loadingShow(dispatch, false);
    });
};
export const addMemberProfileService =
  (d, nav) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "POST",
      url: `${baseURL}/profiles/${
        getState().usersReducer.primaryUser.patientId
      }/families/add`,
      data: d,
    };

    return await axiosInterceptors(config)
      .then(async (response) => {
        console.log(response);
        if (response.status === 201) {
          await dispatch(getProfileInfo(true));
        }
        loadingShow(dispatch, false);
      })
      .catch(function (error) {
        console.log(error, "error");
        errormsg(error);
        loadingShow(dispatch, false);
      });
  };
export const getProfileInfo =
  (d = false) =>
  async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "GET",
      url: `${baseURL}/profiles/${getState().loginReducer.patientId}`,
    };

    return await axiosInterceptors(config)
      .then(async (response) => {
        console.log(response);
        if (d) {
          dispatch({ type: "USERS_PRIMARY_USER", payload: response.data.data });
        } else {
          dispatch({ type: "USERS_PRIMARY_USER", payload: response.data.data });
          dispatch({
            type: "USERS_SELECTED_USER",
            payload: response.data.data,
          });
        }
        loadingShow(dispatch, false);
      })
      .catch(function (error) {
        errormsg(error);
        dispatch({ type: "LOGIN_STEP", payload: 2 });
        loadingShow(dispatch, false);
      });
  };
export const getPatientAddress =
  (d = false) =>
  async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "GET",
      url: `${baseURL}/profiles/${
        getState().usersReducer.selectedUser.patientId
      }/addresses`,
    };

    return await axiosInterceptors(config)
      .then(async (response) => {
        console.log(response);
        dispatch({
          type: "USERS_SELECTED_USER_ADDRESS",
          payload: response.data.data,
        });
        if (Object.keys(getState().usersReducer.selectedAddress).length === 0) {
          dispatch({
            type: "USERS_SELECTED_ADDRESS",
            payload: response.data.data[0],
          });
        }
        loadingShow(dispatch, false);
      })
      .catch(function (error) {
        console.log(error);
        errormsg(error);
        dispatch({ type: "USERS_SELECTED_USER_ADDRESS", payload: [] });
        loadingShow(dispatch, false);
      });
  };
export const getCategoryList = (categoryType) => async (dispatch, getState) => {
  const data = {
    searchCriteria: {
      categoryType: categoryType,
      isActive: true,
    },
  };

  const config = {
    method: "post",
    url: `${baseURL}/masters/categories/findall`,
    data: data,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getVitalMasterService = () => async (dispatch, getState) => {
  const config = {
    method: "GET",
    url: `${baseURL}/masters/categories/vit2`,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      dispatch({ type: "VITALS_MASTER", payload: response.data.data.value });
      return response.data.data;
    })
    .catch(function (error) {
      dispatch({ type: "VITALS_MASTER", payload: [] });
      errormsg(error);
    });
};
export const getMyVitalDetails = (patientId) => {
  const config = {
    method: "GET",
    url: `${baseURL}/caretakers/casesheet/patient/vitalsdetail/${patientId}`,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      // dispatch({
      //   type: "USERS_VITALS_DETAILS",
      //   payload: response.data.data.vital,
      // });
      // console.log(response.data.data);
      return response.data.data.vital;
    })
    .catch(function (error) {
      // dispatch({ type: "USERS_VITALS_DETAILS", payload: [] });
      errormsg(error);
    });
};
export const getVitalFieldes = () => (dispatch, getState) => {
  const config = {
    method: "GET",
    url: `${baseURL}/caretakers/masters/categories/vit2`,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      dispatch({
        type: "VITAL_FILED",
        payload: response.data.data.value,
      });
      // dispatch({
      //   type: "USERS_VITALS_DETAILS",
      //   payload: response.data.data.vital,
      // });
      // console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      // dispatch({ type: "USERS_VITALS_DETAILS", payload: [] });
      errormsg(error);
    });
};
export const getSubscriptions = () => (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "GET",
    url: `${baseURL}/user/subscription/all-subscription/`,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      dispatch({
        type: "GET_SUBSCRIPTION",
        payload: response.data.data,
      });
      loadingShow(dispatch, false);
      return response.data.data;
    })
    .catch(function (error) {
      loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const getPatientSubscriptions = () => (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "post",
    url: `${baseURL}/subscriptions/sub/patient-id/`,
    data: { patientId: getState().usersReducer.selectedUser.patientId },
  };

  return axiosInterceptors(config)
    .then(function (response) {
      dispatch({
        type: "GET_PATIENT_SUBSCRIPTION",
        payload: response.data.data,
      });
      loadingShow(dispatch, false);
      return response.data.data;
    })
    .catch(function (error) {
      loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const buySubscriptions = (data) => (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "post",
    url: `${baseURL}/subscriptions/order/create/`,
    data,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      // dispatch({
      //   type: "GET_PATIENT_SUBSCRIPTION",
      //   payload: response.data.dat,
      // });
      loadingShow(dispatch, false);
      return response.data;
    })
    .catch(function (error) {
      loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const updateVital = (data) => (dispatch, getState) => {
  const config = {
    method: "POST",
    url: `${baseURL}/caretakers/casesheet/patientinfo/`,
    data,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      // dispatch({ type: "USERS_VITALS_DETAILS", payload: [] });
      errormsg(error);
    });
};
export const getBookedSlot =
  (labId, date, pincode, lat, lon) => (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "GET",
      url: `${baseURL}/laboratory/laboratory/slots/get-by-date/${labId}/${date}/?pincode=${pincode}&lat=${lat}&long=${lon}&patient=true`,
    };

    return axiosInterceptors(config)
      .then(function (response) {
        loadingShow(dispatch, false);
        dispatch({
          type: "GET_BOOKED_SLOT",
          payload: response.data.data,
        });
        // dispatch({
        //   type: "USERS_VITALS_DETAILS",
        //   payload: response.data.data.vital,
        // });
        // console.log(response.data.data);
        return response.data.data;
      })
      .catch(function (error) {
        loadingShow(dispatch, false);
        // dispatch({ type: "USERS_VITALS_DETAILS", payload: [] });
        errormsg(error);
      });
  };
export const getMyPackages = () => (dispatch, getState) => {
  const config = {
    method: "GET",
    url: `${baseURL}/laboratory/laboratory/bookings/get-my-packages/${
      getState().usersReducer.selectedUser.patientId
    }/`,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      dispatch({
        type: "MY_PACKAGE",
        payload: response.data.data,
      });
      // dispatch({
      //   type: "USERS_VITALS_DETAILS",
      //   payload: response.data.data.vital,
      // });
      // console.log(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      // dispatch({ type: "USERS_VITALS_DETAILS", payload: [] });
      errormsg(error);
    });
};
export const getMyVitalGraph =
  (patientId, data) => async (dispatch, getState) => {
    // const data = {
    //   startDate: moment(startDate).format("DD-MM-YYYY"),
    //   month: "02",
    //   endDate: moment(endDate).format("DD-MM-YYYY"),
    // };
    console.log(data);

    const config = {
      method: "POST",
      url: `${baseURL}/caretakers/casesheet/vitalinfo/${patientId}`,
      data,
    };

    return axiosInterceptors(config)
      .then(function (response) {
        dispatch({ type: "VITAL_DATA", payload: response.data.data });
        // dispatch({
        //   type: "USERS_VITALS_DETAILS",
        //   payload: response.data.data.vital,
        // });
        // console.log(response.data.data);
        return response.data.data;
      })
      .catch(function (error) {
        // dispatch({ type: "USERS_VITALS_DETAILS", payload: [] });
        errormsg(error);
      });
  };
export const bookRemainPackage =
  (id, data, nav) => async (dispatch, getState) => {
    console.log(data);
    loadingShow(dispatch, true);
    const config = {
      method: "POST",
      url: `${baseURL}/laboratory/laboratory/bookings/confirm-package/${id}/`,
      data,
    };

    return axiosInterceptors(config)
      .then(function (response) {
        loadingShow(dispatch, false);
        dispatch({ type: "PROFILE_MENU_TAB", payload: 8 });

        nav("/profile");
        return response.data.data;
      })
      .catch(function (error) {
        loadingShow(dispatch, false);
        errormsg(error);
      });
  };
export const getFollowUpData = () => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "GET",
    url: `${baseURL}/caretakers/casesheet/followupAppointments/${
      getState().usersReducer.selectedUser.patientId
    }`,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      loadingShow(dispatch, false);
      dispatch({ type: "GET_FOLLOWUP_DATA", payload: response.data.data });
      return response.data.data;
    })
    .catch(function (error) {
      loadingShow(dispatch, false);
      dispatch({ type: "GET_FOLLOWUP_DATA", payload: [] });
      errormsg(error);
    });
};
export const getReferalDoctor = () => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "GET",
    url: `${baseURL}/caretakers/casesheet/referrals/${
      getState().usersReducer.selectedUser.patientId
    }`,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      loadingShow(dispatch, false);
      dispatch({ type: "GET_REFERAL_DATA", payload: response.data.data });
      return response.data.data;
    })
    .catch(function (error) {
      loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const getFollowUpSelectedDoctor =
  (data, nav) => async (dispatch, getState) => {
    const config = {
      method: "post",
      url: `${baseURL}/caretakers/doctors`,
      data,
    };

    return axiosInterceptors(config)
      .then(function (response) {
        if (response.data.data.items[0]) {
          dispatch({
            type: "CONSULTATION_SELECTED_DOCTOR",
            payload: response.data.data.items[0],
          });
          nav("/consultation");
          dispatch({
            type: "CONSULTATION_STEP",
            payload: 1,
          });
          return response.data.data;
        }
      })
      .catch(function (error) {
        errormsg(error);
      });
  };
export const fileAttachmentUpload = async (
  file,
  url,
  fileName,
  fileUrl,
  toastShow = true
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      axiosInterceptors
        .request({
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": file?.type,
          },
          method: "PUT",
          data: reader.result,
          url: url,
          timeout: 0,
          onUploadProgress: (progressEvent) => {
            // const uploadProgress = (progressEvent.loaded / progressEvent.total) * 100 - 10;
          },
        })
        .then((response) => {
          if (response.status === 200) {
            if (toastShow) {
              triggerToastSuccess(`File Uploaded Successfully`);
            }
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err);
          errormsg(err);
        });
    };
    reader.onerror = (err) => {
      err = { error: { message: "file read error" } };
      reject(err);
    };
    reader.readAsArrayBuffer(file);
  });
};
export const fileAttachmentUploadURLGen = async (
  fileDetails,
  picUploadBody
) => {
  try {
    return await axiosInterceptors({
      method: "POST",
      url: `${baseURL}/file-storage/get-signed-url/`,
      data: picUploadBody,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          if (res.data.status >= 200 && res.data.status < 400) {
            const url = res.data.data.signedUrl;
            const fileName = res.data.data.filename;
            const fileUrl = res.data.data.fileUrl;
            const response = await fileAttachmentUpload(
              fileDetails,
              url,
              fileName,
              fileUrl
            );
            if (response.status === 200) return fileUrl;
            triggerToastError(`File Upload failed. Try after Sometimes.`);
          }
        }
      })
      .catch((err) => {
        errormsg(err);
      });
  } catch (error) {}
};
export const fileAttachmentUploadSignURL = async (picUploadBody) => {
  try {
    return await axiosInterceptors({
      method: "POST",
      url: `${baseURL}/file-storage/get-signed-url/`,
      data: picUploadBody,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          if (res.data.status >= 200 && res.data.status < 400) {
            const url = res.data.data.signedUrl;
            // const fileName = res.data.data.filename;
            // const fileUrl = res.data.data.fileUrl;
            return url;
          }
        }
      })
      .catch((err) => {
        errormsg(err);
      });
  } catch (error) {}
};
export const fileAttachmentUploadURLGenName = async (
  fileDetails,
  picUploadBody,
  toastShow = true
) => {
  try {
    return await axiosInterceptors({
      method: "POST",
      url: `${baseURL}/file-storage/get-signed-url/`,
      data: picUploadBody,
    })
      .then(async (res) => {
        if (res.status >= 200) {
          if (res.data.status >= 200 && res.data.status < 400) {
            const url = res.data.data.signedUrl;
            const fileName = res.data.data.filename;
            const fileUrl = res.data.data.fileUrl;
            const response = await fileAttachmentUpload(
              fileDetails,
              url,
              fileName,
              fileUrl,
              toastShow
            );
            if (response.status === 200) return fileName;
            triggerToastError(`File Upload failed. Try after Sometimes.`);
          }
        }
      })
      .catch((err) => {
        errormsg(err);
      });
  } catch (error) {}
};
export const getUserUploadedHealthRecords =
  (pageNumber, itemPerPage, sort) => async (dispatch, getState) => {
    await loadingShow(dispatch, true);
    const data = {
      itemsPerPage: itemPerPage,
      pageNumber: pageNumber,
      searchCriteria: { casesheetId: null },
      sortInstruction: [{ attributeName: "createdOn", sortDirection: sort }],
    };

    const config = {
      method: "post",
      url: `${baseURL}/caretakers/casesheet/${
        getState().usersReducer.selectedUser.patientId
      }/patient-health-records/`,
      data: data,
    };

    return await axiosInterceptors(config)
      .then(function (response) {
        loadingShow(dispatch, false);
        dispatch({
          type: "USER_HEALTH_RECORD",
          payload: response.data.data,
        });
        return response.data.data;
      })
      .catch(function (error) {
        loadingShow(dispatch, false);
        errormsg(error);
      });
  };

export const uploadHealthRecords = (d) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "post",
    url: `${baseURL}/caretakers/casesheet/${
      getState().usersReducer.selectedUser.patientId
    }/healthrecords/`,
    data: d,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      loadingShow(dispatch, false);
      return response.data.data;
    })
    .catch(function (error) {
      loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const deleteHealthRecords = (id) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "DELETE",
    url: `${baseURL}/caretakers/casesheet/${
      getState().usersReducer.selectedUser.patientId
    }/healthrecords/?id=${id}`,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      loadingShow(dispatch, false);
      triggerToastSuccess(response.data.data);
      return response.data.data;
    })
    .catch(function (error) {
      loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const fileAttachmentDownloadURLGenall =
  (fileName, props) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    let signedUrl;
    try {
      await axiosInterceptors({
        method: "POST",
        url: `${baseURL}/file-storage/get-signed-url/`,
        data: {
          filename: fileName,
          objectType: "close",
          apiType: "download",
        },
      })
        .then((res) => {
          if (res.data.status >= 200 && res.data.status < 400) {
            loadingShow(dispatch, false);
            signedUrl = res.data.data;
          }
        })
        .catch((error) => {
          loadingShow(dispatch, false);
          errormsg(error, props);
        });
    } catch (error) {}
    return signedUrl;
  };
export const fileAttachmentDownload =
  (url, fileType, props) => async (dispatch, getState) => {
    // console.log('fileAttachmentDownload service');
    try {
      await axios({
        method: "GET",
        url: `${url}`,
        responseType: "arraybuffer",
      })
        .then((res) => {
          if (res.status >= 200) {
            if (res.data) {
              const blob = new Blob([res.data], { type: fileType });
              saveAs(blob);
            }
          }
        })
        .catch((err) => {
          errormsg(err, props);
        });
    } catch (error) {
      // dispatch(addOverlayLoading(false));
      console.log(error);
      // console.log(error.res, 'error');
    }
  };
export const fileAttachmentDownloadURLGen =
  (fileName, props) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    let signedUrl;
    try {
      await axiosInterceptors({
        method: "POST",
        url: `${baseURL}/file-storage/get-signed-url/`,
        data: {
          filename: fileName,
          objectType: "close",
          apiType: "download",
        },
      })
        .then((res) => {
          if (res.data.status >= 200 && res.data.status < 400) {
            loadingShow(dispatch, false);
            signedUrl = res.data.data.signedUrl;
          }
        })
        .catch((error) => {
          loadingShow(dispatch, false);
          errormsg(error, props);
        });
    } catch (error) {}
    return signedUrl;
  };
export const fileAttachmentBillingDownloadURLGen =
  (fileName) => async (dispatch, getState) => {
    let signedUrl;
    try {
      await axiosInterceptors({
        method: "GET",
        url: `${baseURL}/filegenerate/bills/${fileName}`,
        // data: {
        //   filename: fileName,
        //   objectType: "close",
        //   apiType: "download",
        // },
      })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            console.log(res);
            signedUrl = res.data.data.signedUrl;
          }
        })
        .catch((error) => {
          errormsg(error);
        });
    } catch (error) {}
    return signedUrl;
  };
export const fileAttachmentBillingDownloadURLGenall =
  (fileName) => async (dispatch, getState) => {
    let signedUrl;
    try {
      await axiosInterceptors({
        method: "GET",
        url: `${baseURL}/filegenerate/bills/${fileName}`,
        // data: {
        //   filename: fileName,
        //   objectType: "close",
        //   apiType: "download",
        // },
      })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            console.log(res);
            signedUrl = res.data.data;
          }
        })
        .catch((error) => {
          errormsg(error);
        });
    } catch (error) {}
    return signedUrl;
  };
export const fileAttachmentCasesheetDownloadURLGenall =
  (fileName) => async (dispatch, getState) => {
    let signedUrl;
    try {
      await axiosInterceptors({
        method: "GET",
        url: `${baseURL}/caretakers/filegenerate/casesheetpdf/${fileName}?patient=true`,
        // data: {
        //   filename: fileName,
        //   objectType: "close",
        //   apiType: "download",
        // },
      })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            console.log(res);
            signedUrl = res.data.data;
          }
        })
        .catch((error) => {
          errormsg(error);
        });
    } catch (error) {}
    return signedUrl;
  };
export const fileAttachmentLabBillingDownloadURLGen =
  (fileName) => async (dispatch, getState) => {
    let signedUrl;
    try {
      await axiosInterceptors({
        method: "GET",
        url: `${baseURL}/filegenerate/labreport/${fileName}`,
        // data: {
        //   filename: fileName,
        //   objectType: "close",
        //   apiType: "download",
        // },
      })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            console.log(res);
            signedUrl = res.data.data.signedUrl;
          }
        })
        .catch((error) => {
          errormsg(error);
        });
    } catch (error) {}
    return signedUrl;
  };
export const fileAttachmentLabBillingDownloadURLGenall =
  (fileName) => async (dispatch, getState) => {
    let signedUrl;
    try {
      await axiosInterceptors({
        method: "GET",
        url: `${baseURL}/filegenerate/labreport/${fileName}`,
        // data: {
        //   filename: fileName,
        //   objectType: "close",
        //   apiType: "download",
        // },
      })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            console.log(res);
            signedUrl = res.data.data;
          }
        })
        .catch((error) => {
          errormsg(error);
        });
    } catch (error) {}
    return signedUrl;
  };
export const fileAttachmentPharmacyBillingDownloadURLGen =
  (fileName) => async (dispatch, getState) => {
    let signedUrl;
    try {
      await axiosInterceptors({
        method: "GET",
        url: `${baseURL}/pharmacy/orders/patient-pharmacy-order/generate/invoice/${fileName}/`,
        // data: {
        //   filename: fileName,
        //   objectType: "close",
        //   apiType: "download",
        // },
      })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            console.log(res);
            signedUrl = res.data.data.signedUrl;
          }
        })
        .catch((error) => {
          errormsg(error);
        });
    } catch (error) {}
    return signedUrl;
  };
export const fileAttachmentPharmacyBillingDownloadURLGenall =
  (fileName) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    let signedUrl;
    try {
      await axiosInterceptors({
        method: "GET",
        url: `${baseURL}/pharmacy/orders/patient-pharmacy-order/generate/invoice/${fileName}/`,
        // data: {
        //   filename: fileName,
        //   objectType: "close",
        //   apiType: "download",
        // },
      })
        .then((res) => {
          console.log(res);
          if (res.data.code === 200) {
            loadingShow(dispatch, false);
            console.log(res);
            signedUrl = res.data.data;
          }
        })
        .catch((error) => {
          loadingShow(dispatch, false);
          errormsg(error);
        });
    } catch (error) {}
    return signedUrl;
  };
export const addNewAddressService = (d) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "post",
    url: `${baseURL}/profiles/${
      getState().usersReducer.selectedUser.patientId
    }/addresses/add`,
    data: d,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      loadingShow(dispatch, false);
      return response.data.data;
    })
    .catch(function (error) {
      loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const getDoctorsService =
  (itemPerPage, pageNumber, type, nav) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    dispatch({ type: "CONSULTATION_TYPE", payload: type });
    const data = {
      itemsPerPage: itemPerPage,
      pageNumber: pageNumber,
      searchCriteria: {
        referralCodeName: getState().usersReducer.primaryUser.referralCode,
        empStatus: true,
        speciality: type,
        role: ["ROLE_DOCTOR", "ROLE_DIET", "ROLE_EDUCATOR"],
        location: getState().usersReducer.selectedAddress,
      },
    };
    const config = {
      method: "post",
      url: `${baseURL}/caretakers/doctors`,
      data: data,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        loadingShow(dispatch, false);
        if (response.data.data.items.length > 0) {
          dispatch({
            type: "CONSULTATION_SPECILITY_DOCTORS",
            payload: response.data.data.items,
          });
          dispatch({
            type: "CONSULTATION_SELECTED_DOCTOR",
            payload: response.data.data.items[0],
          });
          dispatch({
            type: "CONSULTATION_STEP",
            payload: 1,
          });
        } else {
          dispatch({ type: "CONSULTATION_SPECILITY_DOCTORS", payload: [] });
          triggerToastWarn("No Doctors are available");
        }
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
      });
  };
export const getPatientPrescriptions =
  (pageNumber, itemPerPage, type, nav, sort) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    // dispatch({ type: "PHARMACY_OPTIONS", payload: type });
    const data = {
      itemsPerPage: itemPerPage,
      pageNumber: pageNumber,
      searchCriteria: {
        casesheetId__ne: type,
        documentType: "PRU1",
      },
      sortInstruction: [
        {
          attributeName: "createdOn",
          sortDirection: sort,
        },
      ],
    };
    const config = {
      method: "post",
      url: `${baseURL}/caretakers/casesheet/${
        getState().usersReducer.selectedUser.patientId
      }/patient-health-records/`,
      data: data,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
      });
  };
export const getPatientReports =
  (pageNumber, itemPerPage, type, nav, sort) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    // dispatch({ type: "PHARMACY_OPTIONS", payload: type });
    const data = {
      itemsPerPage: itemPerPage,
      pageNumber: pageNumber,
      searchCriteria: {
        // casesheetId__ne: null,
        documentType__nin: ["PRU1", "PRU6"],
      },
      sortInstruction: [
        {
          attributeName: "createdOn",
          sortDirection: sort,
        },
      ],
    };
    const config = {
      method: "post",
      url: `${baseURL}/caretakers/casesheet/${
        getState().usersReducer.selectedUser.patientId
      }/patient-health-records/`,
      data: data,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
      });
  };
export const getPharmacyRequest =
  (pageNumber, itemPerPage, id, nav, sort) => async (dispatch, getState) => {
    // loadingShow(dispatch, true);
    // dispatch({ type: "PHARMACY_OPTIONS", payload: type });
    const data = {
      itemsPerPage: itemPerPage,
      pageNumber: pageNumber,
      searchCriteria: {
        patientId: getState().usersReducer.selectedUser.patientId,
        status__in: ["DRAFT"],
      },
      sortInstruction: [
        {
          attributeName: "createdOn",
          sortDirection: sort,
        },
      ],
    };
    const config = {
      method: "post",
      url: `${baseURL}/pharmacy/orders/patient-order/all/list/`,
      data: data,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        // loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        errormsg(error);
        // loadingShow(dispatch, false);
      });
  };
export const getPatientPharmacyOrders =
  (pageNumber, itemPerPage, id, nav) => async (dispatch, getState) => {
    // dispatch({ type: "PHARMACY_OPTIONS", payload: type });
    loadingShow(dispatch, true);
    const config = {
      method: "get",
      url: `${baseURL}/pharmacy/orders/patient-pharmacy-order/get-by/patient-id/${id}?pageNumber=${pageNumber}&totalSize=${itemPerPage}`,
    };

    return axiosInterceptors(config)
      .then((response) => {
        loadingShow(dispatch, false);
        console.log(response);
        return response.data.data;
      })
      .catch(function (error) {
        loadingShow(dispatch, false);
        errormsg(error);
      });
  };
export const getPatientMessage = () => async (dispatch, getState) => {
  // dispatch({ type: "PHARMACY_OPTIONS", payload: type });
  // loadingShow(dispatch, true);
  const config = {
    method: "get",
    url: `${baseURL}/messaging/chat/pa/requests?senderId=${
      getState().usersReducer.selectedUser.patientId
    }`,
  };

  return axiosInterceptors(config)
    .then((response) => {
      // loadingShow(dispatch, false);
      console.log(response);
      dispatch({
        type: "PATIENT_MESSAGE",
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch(function (error) {
      // loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const getPatientChat = () => async (dispatch, getState) => {
  // dispatch({ type: "PHARMACY_OPTIONS", payload: type });
  // loadingShow(dispatch, true);
  const config = {
    method: "get",
    url: `${baseURL}/messaging/chat/pa?channel=${
      getState().usersReducer.selectedUser.patientId
    }`,
  };

  return axiosInterceptors(config)
    .then((response) => {
      // loadingShow(dispatch, false);
      console.log(response);
      dispatch({
        type: "PATIENT_CHAT",
        payload: response.data.data,
      });
      return response.data.data;
    })
    .catch(function (error) {
      // loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const postPatientChat = (data) => async (dispatch, getState) => {
  const config = {
    method: "get",
    url: `${baseURL}/messaging/chat/pa`,
    data: data,
  };

  return axiosInterceptors(config)
    .then((response) => {
      // loadingShow(dispatch, false);
      console.log(response);
      return response.data.data;
    })
    .catch(function (error) {
      // loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const getPatientLabOrders = (data) => async (dispatch, getState) => {
  // dispatch({ type: "PHARMACY_OPTIONS", payload: type });
  loadingShow(dispatch, true);

  const config = {
    method: "post",
    url: `${baseURL}/laboratory/laboratory/bookings/all/list/`,
    data: data,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      loadingShow(dispatch, false);
      dispatch({ type: "PATIENT_LABORDER", payload: response.data.data });
      return response.data.data;
    })
    .catch(function (error) {
      loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const getPatientPharmacyOrderTrack =
  (id, nav) => async (dispatch, getState) => {
    // dispatch({ type: "PHARMACY_OPTIONS", payload: type });
    const config = {
      method: "get",
      url: `${baseURL}/pharmacy/orders/patient-pharmacy-order/get/history/${id}/`,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);

        if (response.data.data) {
          dispatch({
            type: "PHARMACY_ORDER_TRACKING",
            payload: response.data.data.reverse(),
          });
          return response.data.data.reverse();
        }
      })
      .catch(function (error) {
        errormsg(error);
      });
  };
export const getPharmacyPromoCode =
  (data, nav) => async (dispatch, getState) => {
    // dispatch({ type: "PHARMACY_OPTIONS", payload: type });
    const config = {
      method: "post",
      url: `${baseURL}/pharmacy/orders/patient-pharmacy-order/get/order-preview/`,
      data: data,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);

        if (response.data.data) {
          dispatch({
            type: "PHARMACY_PROMO_CODE",
            payload: response.data.data,
          });
          return response.data.data;
        }
      })
      .catch(function (error) {
        errormsg(error);
      });
  };
export const getPatientPopup = (data, props) => async (dispatch, getState) => {
  // dispatch({ type: "PHARMACY_OPTIONS", payload: type });
  const config = {
    method: "post",
    url: `${baseURL}/consultations/send/popups/`,
    data: data,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);

      if (response.data.data) {
        dispatch({
          type: "GET_POPUP",
          payload: response.data.data,
        });
        return response.data.data;
      }
    })
    .catch(function (error) {
      errormsg(error);
    });
};
export const getPatientPrescriptionsAndBy =
  (type) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    // dispatch({ type: "PHARMACY_OPTIONS", payload: type });

    const config = {
      method: "get",
      url: `${baseURL}/caretakers/casesheet/${type}`,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        loadingShow(dispatch, false);
        if (response.data.data) {
          dispatch({
            type: "GET_PRESCRIPTION_ITEMS",
            payload: response.data.data.medicalPrescription,
          });
          // dispatch({
          //   type: "CONSULTATION_SELECTED_DOCTOR",
          //   payload: response.data.data.items[0],
          // });
          // dispatch({
          //   type: "PHARMACY_OPTIONS",
          //   payload: 1,
          // });
          return response.data.data;
        } else {
          dispatch({ type: "PHARMACY_PRESCRIPTIONS", payload: [] });
        }
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
      });
  };
export const getPatientRequestInfo = (type) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  // dispatch({ type: "PHARMACY_OPTIONS", payload: type });

  const config = {
    method: "get",
    url: `${baseURL}/pharmacy/orders/patient-order/${type}/`,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      loadingShow(dispatch, false);
      if (response.data.data) {
        dispatch({
          type: "GET_REQUEST_INFO",
          payload: response.data.data,
        });
        // dispatch({
        //   type: "CONSULTATION_SELECTED_DOCTOR",
        //   payload: response.data.data.items[0],
        // });
        // dispatch({
        //   type: "PHARMACY_OPTIONS",
        //   payload: 1,
        // });
        return response.data.data;
      } else {
        dispatch({ type: "GET_REQUEST_INFO", payload: [] });
      }
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
    });
};
export const getPatientCartData = (type) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  // dispatch({ type: "PHARMACY_OPTIONS", payload: type });

  const config = {
    method: "get",
    url: `${baseURL}/pharmacy/orders/patient-order/get/cart/${
      getState().usersReducer.selectedUser.patientId
    }/`,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      loadingShow(dispatch, false);
      if (response.data.data) {
        dispatch({
          type: "GET_PATIENT_CART",
          payload: response.data.data,
        });
        // dispatch({
        //   type: "CONSULTATION_SELECTED_DOCTOR",
        //   payload: response.data.data.items[0],
        // });
        // dispatch({
        //   type: "PHARMACY_OPTIONS",
        //   payload: 1,
        // });
        return response.data.data;
      } else {
        dispatch({ type: "GET_PATIENT_CART", payload: [] });
      }
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
    });
};
export const getPharmacyAddtoCart =
  (type, method, data) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    // dispatch({ type: "PHARMACY_OPTIONS", payload: type });

    const config = {
      method: method,
      url: `${baseURL}/pharmacy/orders/patient-order/${type}`,
      data: data,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        loadingShow(dispatch, false);
        if (response.data.data) {
          dispatch({
            type: "GET_PHARMACY_ADD_CART",
            payload: response.data.data,
          });
          dispatch(getPatientCartData());
          if (method !== "post" && data.status === "DRAFT") {
            dispatch({ type: "GET_PATIENT_CART", payload: {} });
            dispatch({ type: "PHARMACY_OPTIONS", payload: 3 });
          }
          if (method === "post" && data.uploadedPrescription) {
            dispatch({ type: "GET_PATIENT_CART", payload: {} });
            dispatch({ type: "PHARMACY_OPTIONS", payload: 3 });
          }
          if (method === "delete") {
            dispatch({
              type: "GET_PRESCRIPTIOPN_BYINFO",
              payload: {},
            });
          }
          // dispatch({
          //   type: "CONSULTATION_SELECTED_DOCTOR",
          //   payload: response.data.data.items[0],
          // });
          // dispatch({
          //   type: "PHARMACY_OPTIONS",
          //   payload: 1,
          // });
          return response.data.data;
        } else {
          dispatch({ type: "GET_PHARMACY_ADD_CART", payload: [] });
        }
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
      });
  };
export const getPharmacyMakePayment = (data) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  // dispatch({ type: "PHARMACY_OPTIONS", payload: type });

  const config = {
    method: "put",
    url: `${baseURL}/pharmacy/payment-gateway/pharmacyOrder/payment/pay/`,
    data: data,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      loadingShow(dispatch, false);
      if (response.data.data) {
        dispatch({
          type: "GET_PHARMACY_PAY",
          payload: response.data.data,
        });
        // dispatch({
        //   type: "CONSULTATION_SELECTED_DOCTOR",
        //   payload: response.data.data.items[0],
        // });
        // dispatch({
        //   type: "PHARMACY_OPTIONS",
        //   payload: 1,
        // });
        return response.data;
      } else {
        dispatch({ type: "GET_PHARMACY_PAY", payload: [] });
      }
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
    });
};
export const getSlotsByDoctorAndDate = (date) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "GET",
    url: `${baseURL}/caretakers/user/roaster/slots-by-date?doctorId=${
      getState().consultationReducer.consultationSelectedDoctor.empId
    }&date=${date}`,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (
        response &&
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data)
      ) {
        dispatch({ type: "CONSULTATION_SLOTS", payload: response.data.data });
      } else {
        dispatch({ type: "CONSULTATION_SLOTS", payload: [] });
      }
      loadingShow(dispatch, false);
    })
    .catch(function (error) {
      dispatch({ type: "CONSULTATION_SLOTS", payload: [] });
      errormsg(error);
      loadingShow(dispatch, false);
    });
};
export const createConsultationOrder = (data) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const result = {
    ...data,
    consultationDate: moment(data.consultationDate).format("YYYY-MM-DD"),
    speciality: getState().globalReducer.specialists.items.find(
      (val) => val.codeName === data.speciality
    ),
  };
  const config = {
    method: "POST",
    url: `${baseURL}/consultations/create`,
    data: result,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.status === 201) {
        dispatch({
          type: "CONSULTATION_ORDER_DETAILS",
          payload: response.data.data.orderId,
        });
        return response.data.data.orderId;
        // dispatch(getOrderIdRazerPay(response.data.data.consultationId, data.amount, data.modeOfConsultation));
      }
      loadingShow(dispatch, false);
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
    });
};
export const getOrderIdInformation =
  (consultationId, type, nav, free = false) =>
  async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const result = {
      orderId: consultationId,
      remarks: "",
      updatedBy: getState().usersReducer.selectedUser.patientId,
      paymentMode: type,
    };
    const config = {
      method: "PUT",
      url: `${baseURL}/payment-gateway/consultationOrder/payment/pay/`,
      data: result,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        if (response.status === 201) {
          if (type === "COD" || free) {
            dispatch({
              type: "CONSULTATION_SUCCESS_POPUP",
              payload: { isActive: true, data: response.data.data },
            });
            dispatch({
              type: "PROFILE_MENU_TAB",
              payload: 3,
            });
            dispatch({
              type: "CONSULTATION_TYPE",
              payload: "",
            });
            dispatch({
              type: "CONSULTATION_SPECILITY_DOCTORS",
              payload: [],
            });
            dispatch({
              type: "CONSULTATION_STEP",
              payload: 0,
            });
            dispatch({
              type: "CONSULTATION_SELECTED_DOCTOR",
              payload: {},
            });
            dispatch({
              type: "BOOKAPPOINTMENT_STEP",
              payload: 0,
            });
            dispatch({
              type: "CONSULTATION_SLOTS",
              payload: [],
            });
            dispatch({
              type: "CONSULTATION_ORDER_DETAILS",
              payload: {},
            });
            dispatch({
              type: "CONSULTATION_COUPONCODE",
              payload: {},
            });
            dispatch({
              type: "CONSULTATION_AVAILABILITY_CHECK",
              payload: {},
            });
            dispatch({
              type: "CONSULTATION_ORDER_RAZERPAY_DETAILS",
              payload: {},
            });
            nav("/profile");
            loadingShow(dispatch, false);
          } else {
            dispatch({
              type: "CONSULTATION_ORDER_RAZERPAY_DETAILS",
              payload: response.data.data.orderId,
            });
            loadingShow(dispatch, false);
            return response.data;
          }
          // dispatch({
          //   type: "CONSULTATION_ORDER_RAZERPAY_DETAILS",
          //   payload: response.data.data.orderId,
          // });
        }
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
      });
  };
export const paymentService =
  (orderId, razorpay_order_id, razorpay_payment_id, razorpay_signature, nav) =>
  async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const result = {
      orderId: orderId,
      razorpay_order_id: razorpay_order_id,
      razorpay_payment_id: razorpay_payment_id,
      razorpay_signature: razorpay_signature,
    };
    const config = {
      method: "PUT",
      url: `${baseURL}/payment-gateway/consultationOrder/payment/complete/`,
      data: result,
    };
    try {
      return await axiosInterceptors(config)
        .then((response) => {
          console.log(response);
          if (response.status === 201) {
            dispatch({
              type: "PROFILE_MENU_TAB",
              payload: 3,
            });
            dispatch({
              type: "CONSULTATION_TYPE",
              payload: "",
            });
            dispatch({
              type: "CONSULTATION_SUCCESS_POPUP",
              payload: { isActive: true, data: response.data.data },
            });
            dispatch({
              type: "CONSULTATION_SPECILITY_DOCTORS",
              payload: [],
            });
            dispatch({
              type: "CONSULTATION_STEP",
              payload: 0,
            });
            dispatch({
              type: "CONSULTATION_SELECTED_DOCTOR",
              payload: {},
            });
            dispatch({
              type: "BOOKAPPOINTMENT_STEP",
              payload: 0,
            });
            dispatch({
              type: "CONSULTATION_SLOTS",
              payload: [],
            });
            dispatch({
              type: "CONSULTATION_ORDER_DETAILS",
              payload: {},
            });
            dispatch({
              type: "CONSULTATION_COUPONCODE",
              payload: {},
            });
            dispatch({
              type: "CONSULTATION_AVAILABILITY_CHECK",
              payload: {},
            });
            dispatch({
              type: "CONSULTATION_ORDER_RAZERPAY_DETAILS",
              payload: {},
            });
            dispatch({
              type: "CONSULTATION_ORDER_RAZERPAY_DETAILS",
              payload: {},
            });
            nav("/profile");
            // dispatch(getOrderIdRazerPay(response.data.data.consultationId, data.amount, data.modeOfConsultation));
          }
          loadingShow(dispatch, false);
        })
        .catch(function (error) {
          loadingShow(dispatch, false);
          errormsg(error);
        });
    } catch (error) {
      console.log(error);
      loadingShow(dispatch, false);
    }
  };
export const paymentServicePharmacy =
  (orderId, razorpay_order_id, razorpay_payment_id, razorpay_signature, nav) =>
  async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const result = {
      orderId: orderId,
      razorpay_order_id: razorpay_order_id,
      razorpay_payment_id: razorpay_payment_id,
      razorpay_signature: razorpay_signature,
    };
    const config = {
      method: "PUT",
      url: `${baseURL}/pharmacy/payment-gateway/pharmacyOrder/payment/complete/`,
      data: result,
    };
    try {
      return await axiosInterceptors(config)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            dispatch({ type: "PROFILE_MENU_TAB", payload: 4 });
            dispatch({ type: "ORDER_TAB", payload: 4 });
            dispatch({
              type: "PHARMACY_TRACK_AND_ORDER_TAP",
              payload: 0,
            });
            dispatch({
              type: "PHARMACY_ORDER_SUCCESSPOPUP",
              payload: {
                isActive: true,
                data: getState().pharmacyReducer.pharmacyMyOrder,
              },
            });
            nav("/profile");

            // dispatch(getOrderIdRazerPay(response.data.data.consultationId, data.amount, data.modeOfConsultation));
          }
          loadingShow(dispatch, false);
        })
        .catch(function (error) {
          errormsg(error);
          loadingShow(dispatch, false);
        });
    } catch (error) {
      errormsg(error);
      loadingShow(dispatch, false);
    }
  };
export const paymentServiceLab =
  (orderId, razorpay_order_id, razorpay_payment_id, razorpay_signature, nav) =>
  async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const result = {
      orderId: orderId,
      razorpay_order_id: razorpay_order_id,
      razorpay_payment_id: razorpay_payment_id,
      razorpay_signature: razorpay_signature,
    };
    const config = {
      method: "PUT",
      url: `${baseURL}/payment-gateway/labOrder/payment/complete/`,
      data: result,
    };
    try {
      return await axiosInterceptors(config)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            dispatch({ type: "PROFILE_MENU_TAB", payload: 4 });
            dispatch({ type: "ORDER_TAB", payload: 1 });
            dispatch({
              type: "LAB_ORDER_SUCCESSPOPUP",
              payload: { isActive: true, data: response.data.data },
            });
            // dispatch({
            //   type: "LAB_TAP_OPTION",
            //   payload: 0,
            // });
            nav("/profile");
            // dispatch(getOrderIdRazerPay(response.data.data.consultationId, data.amount, data.modeOfConsultation));
          }
          loadingShow(dispatch, false);
        })
        .catch(function (error) {
          errormsg(error);
          loadingShow(dispatch, false);
        });
    } catch (error) {
      errormsg(error);
      loadingShow(dispatch, false);
    }
  };
export const paymentServiceSubscription =
  (
    orderId,
    razorpay_order_id,
    razorpay_payment_id,
    razorpay_signature,
    id,
    id2,
    nav
  ) =>
  async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const result = {
      orderId: orderId,
      razorpay_order_id: razorpay_order_id,
      razorpay_payment_id: razorpay_payment_id,
      razorpay_signature: razorpay_signature,
      patientId: getState().usersReducer.selectedUser.patientId,
      paymentMode: "Online",
      FromsubscriptionType: id2,
      subscriptionType: id,
    };
    console.log(result);
    const config = {
      method: "PUT",
      url: `${baseURL}/subscriptions/post/pay/`,
      data: result,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);

        dispatch(getSubscriptions());
        dispatch(getPatientSubscriptions());
        // dispatch(getOrderIdRazerPay(response.data.data.consultationId, data.amount, data.modeOfConsultation));

        loadingShow(dispatch, false);
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
      });
  };
export const applyCouponService = (data) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "POST",
    url: `${baseURL}/consultations/coupons/valid/`,
    data,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: "CONSULTATION_COUPONCODE",
          payload: response.data.data,
        });
        triggerToastSuccess("Promo Code Applied Successfully");
      }
      loadingShow(dispatch, false);
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
      return error.response;
    });
};
export const checkAvailabilityService =
  (data) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "POST",
      url: `${baseURL}/laboratory/laboratory/check-availability/home-collection/`,
      data,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          dispatch({
            type: "CONSULTATION_AVAILABILITY_CHECK",
            payload: response.data.data,
          });
        }
        loadingShow(dispatch, false);
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
        return error.response;
      });
  };
export const getLabTrackorder = (data) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "POST",
    url: `${baseURL}/home-collection/bookings/stat/get/`,
    data,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: "LAB_TRACKING",
          payload: response.data.data,
        });
      }
      loadingShow(dispatch, false);
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
      return error.response;
    });
};
export const labBookingInfo = (id) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "get",
    url: `${baseURL}/laboratory/laboratory/bookings/get/${id}/`,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: "LAB_BOOKING_INFO",
          payload: response.data.data,
        });
      }
      loadingShow(dispatch, false);
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
      return error.response;
    });
};
export const updateSlot = (id, data) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "POST",
    url: `${baseURL}/laboratory/laboratory/slots/update/lab-slots/${id}`,
    data: data,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: "LAB_UPDATE_SLOT",
          payload: response.data.data,
        });
        return response.data.data;
      }
      loadingShow(dispatch, false);
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
      return error.response;
    });
};
export const getMyConsultations =
  (pageNumber, itemPerPage, searchCriteria, sortInstruction) =>
  async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const data = {
      itemsPerPage: itemPerPage,
      pageNumber: pageNumber,
      searchCriteria: searchCriteria,
      sortInstruction: sortInstruction,
    };

    const config = {
      method: "post",
      url: `${baseURL}/consultations/findall`,
      data: data,
    };

    return axiosInterceptors(config)
      .then(function (response) {
        loadingShow(dispatch, false);
        dispatch({ type: "MYCONSULTATION", payload: response.data.data });
        return response.data.data;
      })
      .catch(function (error) {
        loadingShow(dispatch, false);
        errormsg(error);
      });
  };
export const getMyLabOrders =
  (pageNumber, itemPerPage, searchCriteria, sortInstruction) =>
  async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const data = {
      itemsPerPage: itemPerPage,
      pageNumber: pageNumber,
      searchCriteria: searchCriteria,
      sortInstruction: sortInstruction,
    };

    const config = {
      method: "post",
      url: `${baseURL}/laboratory/laboratory/bookings/all/list/`,
      data: data,
    };

    return axiosInterceptors(config)
      .then(function (response) {
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        loadingShow(dispatch, false);
        errormsg(error);
      });
  };
export const getMyPharmacyOrders =
  (pageNumber, itemPerPage, patientId) => async (dispatch, getState) => {
    loadingShow(dispatch, true);

    const config = {
      method: "GET",
      url: `${baseURL}/pharmacy/orders/patient-pharmacy-order/get-by/patient-id/${patientId}?pageNumber=${pageNumber}&totalSize=${itemPerPage}`,
    };

    return axiosInterceptors(config)
      .then(function (response) {
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        loadingShow(dispatch, false);
        errormsg(error);
      });
  };
export const getNotificationCountService = () => async (dispatch, getState) => {
  // loadingShow(dispatch, true);

  const config = {
    method: "GET",
    url: `${baseURL}/notifications/${
      getState().usersReducer.selectedUser.patientId
    }/count/unread/`,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      dispatch({
        type: "NOTIFICATION_COUNT",
        payload: response.data.data.totalNotifications,
      });
      // loadingShow(dispatch, false);
    })
    .catch(function (error) {
      // loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const getUnreadedMessageService =
  (pageNumber, itemPerPage, type, sort) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const data = {
      itemsPerPage: itemPerPage,
      pageNumber: pageNumber,
      searchCriteria: {
        memberId: getState().usersReducer.selectedUser.patientId,
        isRead: type,
      },
      sortInstruction: [
        {
          attributeName: "createdOn",
          sortDirection: sort,
        },
      ],
    };

    const config = {
      method: "post",
      url: `${baseURL}/notifications/all/list/`,
      data: data,
    };

    return axiosInterceptors(config)
      .then(function (response) {
        dispatch({
          type: "NOTIFICATION_MESSAGES",
          payload: response.data.data,
        });
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        loadingShow(dispatch, false);
        errormsg(error);
      });
  };
export const readMessageService = (id, type) => async (dispatch, getState) => {
  // loadingShow(dispatch, true);

  const config = {
    method: "GET",
    url: `${baseURL}/notifications/${id}/read`,
  };

  return axiosInterceptors(config)
    .then(function (response) {
      console.log(response);
      // loadingShow(dispatch, false);
    })
    .catch(function (error) {
      // loadingShow(dispatch, false);
      errormsg(error);
    });
};
export const getAgoraRTMToken = async (userId) => {
  let RTMtoken;
  await axiosInterceptors({
    method: "get",
    url: `${baseURL}/messaging/chat-token/generate/rtm?user=${userId}`,
  })
    .then((response) => {
      console.log(response, "getAgoraRTMToken");
      if (response.data.status === 200) {
        RTMtoken = response.data.data.data;
      }
    })
    .catch(() => {});
  return RTMtoken;
};
export const getAgoraRTCToken = async (userId, channel) => {
  let RTCtoken;
  await axiosInterceptors({
    method: "get",
    url: `${baseURL}/messaging/chat-token/generate/rtc?user=${userId}&channel=${channel}`,
  })
    .then((response) => {
      RTCtoken = response?.data?.data?.data;
    })
    .catch(() => {});
  return RTCtoken;
};
// export const getChatHistoryService = (caseSheetId) => async (dispatch, getState) => {
//   // loadingShow(dispatch, true);

//   const config = {
//     method: "GET",
//     url: `${baseURL}/messaging/chat/?channel=${caseSheetId}`,
//   };

//   return axiosInterceptors(config)
//     .then(function (response) {
//       console.log(response);
//       if (response && response.data && Array.isArray(response.data.data)) {
//         dispatch({ type: 'SELECTED_CONSULTATION_CHAT_HISTORY', payload: response.data.data })
//       } else {
//         dispatch({ type: 'SELECTED_CONSULTATION_CHAT_HISTORY', payload: [] })
//       }
//       // loadingShow(dispatch, false);
//     })
//     .catch(function (error) {
//       // loadingShow(dispatch, false);

//       errormsg(error);
//     });
// };
export const getChatHistoryService = async (caseSheetId) => {
  let historyChat = [];
  await axiosInterceptors({
    method: "get",
    url: `${baseURL}/messaging/chat/?channel=${caseSheetId}`,
  })
    .then((response) => {
      console.log(response, "getAgoraRTMToken");
      // if (response.data.status === 200) {
      historyChat = response.data.data || [];
      // }
    })
    .catch(() => {});
  return historyChat;
};
export const getChatHistoryServicePaChart = async (caseSheetId) => {
  let historyChat = [];
  await axiosInterceptors({
    method: "get",
    url: `${baseURL}/messaging/chat/pa?channel=${caseSheetId}`,
  })
    .then((response) => {
      console.log(response, "getAgoraRTMToken");
      // if (response.data.status === 200) {
      historyChat = response.data.data || [];
      // }
    })
    .catch(() => {});
  return historyChat;
};
export const sendChatRequest = async (data) => {
  let result;
  await axiosInterceptors({
    method: "POST",
    url: `${baseURL}/messaging/chat/pa/requests`,
    data: data,
  })
    .then((response) => {
      console.log(response, "getAgoraRTMToken");
      result = response?.data?.data?.data;
    })
    .catch(() => {});
  return result;
};
export const DeleteAddress = async (id) => {
  let result;
  await axiosInterceptors({
    method: "DELETE",
    url: `${baseURL}/profiles/addresses/${id}`,
  })
    .then((response) => {
      result = response?.data?.data;
      triggerToastSuccess(response?.data?.data);
    })
    .catch(() => {});
  return result;
};
export const removeToken = async (data) => {
  let result;
  await axiosInterceptors({
    method: "POST",
    url: `${baseURL}/notifications/token/remove/`,
    data: data,
  })
    .then((response) => {
      result = response?.data?.data;
    })
    .catch(() => {});
  return result;
};
export const ChatService =
  (method, param, data, stateName, callBack) => async (dispatch, getState) => {
    let result;
    method === "get" && loadingShow(dispatch, true);
    await axiosInterceptors({
      method: method,
      url: `${baseURL}/messaging/chat/${param}`,
      data: data,
    })
      .then((response) => {
        result = response.data.data;
        if (callBack) {
          callBack(response?.data?.data?.data);
          // if (method === "get")
          //   dispatch({
          //     type: CARETAKERDATAS,
          //     payload: response.data.data,
          //     stateName: stateName,
          //   });

          loadingShow(dispatch, false);
        }
        console.log(response);
      })
      .catch(() => {
        loadingShow(dispatch, false);
      });
    return result;
  };
export const sendAndSavePushNotificationService =
  (data) => async (dispatch, getState) => {
    // console.log(method, data, 'getAndRefreshFirebaseTokenService called');
    await axiosInterceptors({
      method: "POST",
      url: `${baseURL}/notifications/send/?save=true`,
      data: data,
    }).then((response) => {});
  };
export const sendPushNotificationService = async (data) => {
  // console.log(method, data, 'getAndRefreshFirebaseTokenService called');
  await axiosInterceptors({
    method: "POST",
    url: `${baseURL}/notifications/send/?save=false`,
    data: data,
  }).then((response) => {});
};
// export const fileAttachmentUploadSignURL = async (picUploadBody) => {
//   try {
//     return await axiosInterceptors({
//       method: "POST",
//       url: `${baseURL}/file-storage/get-signed-url/`,
//       data: picUploadBody,
//     })
//       .then(async (res) => {
//         if (res.status >= 200) {
//           if (res.data.status >= 200 && res.data.status < 400) {
//             const url = res.data.data.signedUrl;
//             // const fileName = res.data.data.filename;
//             // const fileUrl = res.data.data.fileUrl;
//             return url;
//           }
//         }
//       })
//       .catch((err) => {
//         errormsg(err);
//       });
//   } catch (error) {}
// };
export const getBanners = () => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "GET",
    url: `${baseURL}/masters/banners`,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.status === 200) {
        dispatch({
          type: "HEALTH_BANNER",
          payload: response.data.data,
        });
      }
      loadingShow(dispatch, false);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
      return error.response;
    });
};
export const getPatientUpcomingConsultation =
  (data) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "POST",
      url: `${baseURL}/consultations/upcoming/consultation/`,
      data: { patientId: getState().usersReducer.selectedUser.patientId },
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          dispatch({
            type: "UPCOMING_CONSULTATION",
            payload: response.data.data,
          });
        }
        loadingShow(dispatch, false);
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
        return error.response;
      });
  };
export const getPackageList =
  (pincode, referral) => async (dispatch, getState) => {
    dispatch({
      type: "PACKAGE_LIST",
      payload: [],
    });
    loadingShow(dispatch, true);
    const config = {
      method: "get",
      url: `${baseURL}/laboratory/laboratory/services/available/packages/?pincode=${pincode}&referralCode=${referral}`,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        if (response.data.data) {
          dispatch({
            type: "PACKAGE_LIST",
            payload: response.data.data,
          });
        }
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
        return error.response;
      });
  };
export const getBannerPackageandTestCheck =
  (pincode, type, packortestId, referral) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "get",
      url: `${baseURL}/laboratory/laboratory/services/available/${type}/?pincode=${pincode}&referenceId=${packortestId}&referralCode=${referral}`,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        if (response.data.data) {
          const res = response.data.data;
          res.ptId = packortestId;
          res.type = type;
          dispatch({
            type: "BANNER_CHECK_AVAILABILITY",
            payload: res,
          });
        }
        if (response.data.data.available) {
          if (type === "packages") {
            dispatch(getSelectedPackageInfo(packortestId));
          } else {
            dispatch(getSelectedTestInfo(packortestId));
            dispatch(
              getSelectedTestCost(response.data.data.labId, packortestId)
            );
          }
        }
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
        return error.response;
      });
  };
export const getTestList =
  (pincode, referral) => async (dispatch, getState) => {
    dispatch({
      type: "TEST_LIST",
      payload: [],
    });
    loadingShow(dispatch, true);
    const config = {
      method: "get",
      url: `${baseURL}/laboratory/laboratory/services/available/tests/?pincode=${pincode}&referralCode=${referral}`,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        if (response.data.data) {
          dispatch({
            type: "TEST_LIST",
            payload: response.data.data,
          });
        }
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
        return error.response;
      });
  };
export const getSelectedTestInfo =
  (testId, cost) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "get",
      url: `${baseURL}/laboratory/lab-masters/tests/${testId}/`,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        if (response.data.data) {
          dispatch({
            type: "SELECTED_TEST_INFO",
            payload: response.data.data,
          });
          // dispatch({
          //   type: "SELECTED_TEST_COST_INFO",
          //   payload: cost,
          // });
          dispatch({ type: "LAB_OPTIONS", payload: 2 });
        }
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
        return error.response;
      });
  };
export const getSelectedTestLab = (testId) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "get",
    url: `${baseURL}/laboratory/laboratory/services/available/tests/?pincode=${
      getState().usersReducer.selectedAddress.pincode
    }&referenceId=${testId}&referralCode=`,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.data.data) {
        dispatch({
          type: "SELECTED_TEST_LAB_INFO",
          payload: response.data.data,
        });
      }
      loadingShow(dispatch, false);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
      return error.response;
    });
};
export const getSelectedTestCost =
  (labId, testId) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "get",
      url: `${baseURL}/laboratory/laboratory-test-mappings/get-test-cost/${labId}/?tests=${testId}`,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        if (response.data.data) {
          dispatch({
            type: "SELECTED_TEST_COST_INFO",
            payload: response.data?.data[0]?.cost,
          });
        }
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
        return error.response;
      });
  };
export const getSelectedPackageInfo =
  (packagesId) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "get",
      url: `${baseURL}/laboratory/packages/${packagesId}/`,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        if (response.data.data) {
          dispatch({
            type: "SELECTED_PACKAGE_INFO",
            payload: response.data.data,
          });
          dispatch({ type: "LAB_OPTIONS", payload: 1 });
        }
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
        return error.response;
      });
  };
export const getLabCart = (patientId) => async (dispatch, getState) => {
  const referralCode = getState().usersReducer.selectedUser.referralCode || "";
  loadingShow(dispatch, true);
  const config = {
    method: "get",
    url: `${baseURL}/laboratory/laboratory/cart/${patientId}?referralCode=${referralCode}`,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.data.data) {
        dispatch({
          type: "GET_CART_DATA",
          payload: response.data.data,
        });
      }
      loadingShow(dispatch, false);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
      return error.response;
    });
};
export const updateLabCart =
  (data, patientId) => async (dispatch, getState) => {
    const referralCode =
      getState().usersReducer.selectedUser.referralCode || "";
    loadingShow(dispatch, true);
    const config = {
      method: "put",
      url: `${baseURL}/laboratory/laboratory/cart/${patientId}/?referralCode=${referralCode}`,
      data: data,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        if (response.data.data) {
          dispatch({
            type: "UPDATE_CART_DATA",
            payload: response.data.data,
          });
          dispatch(getLabCart(data.patientId));
        }
        loadingShow(dispatch, false);
        return response.data.data;
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
        return error.response;
      });
  };
export const ConformLabOrder =
  (data, patientId) => async (dispatch, getState) => {
    loadingShow(dispatch, true);
    const config = {
      method: "post",
      url: `${baseURL}/laboratory/laboratory/bookings/confirm/${patientId}/`,
      data: data,
    };

    return await axiosInterceptors(config)
      .then((response) => {
        console.log(response);
        if (response.data.status === 201) {
          loadingShow(dispatch, false);
          dispatch({
            type: "CONFORM_LAB_ORDER",
            payload: response.data.data,
          });
          dispatch({
            type: "SELECTED_SLOT",
            payload: {},
          });
          dispatch(getLabCart(patientId));
          dispatch({ type: "LAB_OPTIONS", payload: 5 });
          return response.data.data;
        }
      })
      .catch(function (error) {
        errormsg(error);
        loadingShow(dispatch, false);
        return error.response;
      });
  };
export const checkCodAvailability = () => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "post",
    url: `${baseURL}/laboratory/laboratory/check-availability/home-collection/`,
    data: {
      pincode: getState().usersReducer.selectedAddress.pincode,
    },
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.data.data) {
        dispatch({
          type: "CHECK_COD",
          payload: response.data.data,
        });
      }
      loadingShow(dispatch, false);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
      return error.response;
    });
};
export const applyPromoCode = (data) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "post",
    url: `${baseURL}/consultations/coupons/valid/`,
    data: data,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.data.data) {
        dispatch({
          type: "LAB_PROMO_CODE",
          payload: response.data.data,
        });
      }
      loadingShow(dispatch, false);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
      return error.response;
    });
};
export const labPay = (data, nav) => async (dispatch, getState) => {
  loadingShow(dispatch, true);
  const config = {
    method: "put",
    url: `${baseURL}/payment-gateway/labOrder/payment/pay/`,
    data: data,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.data.data) {
        dispatch({
          type: "LAB_PAY_DATA",
          payload: response.data,
        });
      }
      loadingShow(dispatch, false);
      return response.data;
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
      return error.response;
    });
};
export const getSlots = (date, serviceId) => async (dispatch, getState) => {
  const referralCode = getState().usersReducer.selectedUser.referralCode || "";
  loadingShow(dispatch, true);
  const config = {
    method: "get",
    url: `${baseURL}/laboratory/laboratory/slots/get/${date}/?pincode=${
      getState().usersReducer.selectedAddress.pincode
    }&serviceId=${serviceId || ""}&referralCode=${
      referralCode || ""
    }&patient=true`,
  };

  return await axiosInterceptors(config)
    .then((response) => {
      console.log(response);
      if (response.data.data) {
        dispatch({
          type: "GET_SLOT_DATA",
          payload: response.data.data,
        });
      }
      loadingShow(dispatch, false);
      return response.data.data;
    })
    .catch(function (error) {
      errormsg(error);
      loadingShow(dispatch, false);
      return error.response;
    });
};
