import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { blue } from "@mui/material/colors";
import profilePic from "../assets/images/users/profilePic.png";
import { useNavigate } from "react-router-dom";

const SelectPatient = (props) => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { primaryUser, selectedUser } = useSelector(
    (state) => state.usersReducer
  );
  const [familyMembers, setFamilyMembers] = useState([]);
  const joinFamilyMembers = (selfUser) => [selfUser, ...selfUser.familyMembers];
  useEffect(() => {
    const members = joinFamilyMembers(primaryUser);
    console.log(members, "members");
    const memberIndex = members.findIndex(
      (val) => val.patientId === selectedUser.patientId
    );
    console.log(memberIndex);
    // handleChange({}, memberIndex);
    setFamilyMembers(members);
  }, []);
  return (
    <List sx={{ pt: 0 }}>
      {familyMembers.map((member) => (
        <ListItem
          button
          key={member.patientId}
          sx={{
            bgcolor:
              selectedUser.patientId === member.patientId
                ? "#fabb62"
                : "inherit",
            color:
              selectedUser.patientId === member.patientId ? "black" : "inherit",
          }}
          onClick={() => {
            // const findMember = familyMembers.find((val) => val.patientId === member.patientId);
            dispatch({ type: "USERS_SELECTED_USER", payload: member });
          }}
        >
          <ListItemAvatar>
            <Avatar
              sx={{ bgcolor: blue[100], color: blue[600] }}
              src={member.profilePicture ? member.profilePicture : profilePic}
            />
          </ListItemAvatar>
          <ListItemText primary={`${member.firstName} ${member.lastName}`} />
        </ListItem>
      ))}

      <ListItem
        autoFocus
        button
        onClick={() => {
          props.props.setShowModel(false);
          nav("/profile");
          dispatch({ type: "PROFILE_MENU_TAB", payload: 1 });
          setTimeout(() => {
            dispatch({ type: "USER_PROFILE_TAB", payload: 1 });
          }, 1000);
        }}
      >
        <ListItemAvatar>
          <Avatar>
            <AddIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="Add Member" />
      </ListItem>
    </List>
  );
};

export default SelectPatient;
