import React, { useContext, useEffect } from "react";
import EndCall from "./Local/EndCall";
import LocalAudioMute from "./Local/LocalAudioMute";
import LocalVideoMute from "./Local/LocalVideoMute";
import PropsContext from "../PropsContext";
import RtcContext from "../RtcContext";

function LocalControls() {
  const { styleProps, rtcProps, customProps } = useContext(PropsContext);
  const { localBtnContainer } = styleProps || {};
  // custom code
  const { dispatch } = useContext(RtcContext);

  useEffect(() => {
    rtcProps?.audioCall &&  dispatch({
      type: "local-user-mute-video",
      value: [2],
    });
  },[])
   // custom code
  return (
    <div
      style={{
        ...{
          backgroundColor: "#007bff",
          width: "100%",
          height: 70,
          zIndex: 10,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        },
        ...localBtnContainer,
      }}
    >
      {/* {rtcProps.role !== "audience" &&  (<LocalVideoMute />) } */}
      {/* custom code */}
      {rtcProps.role !== "audience" && !rtcProps?.audioCall && (
        <LocalVideoMute />
      )}
       {/* custom code */}
      {rtcProps.role !== "audience" && <LocalAudioMute />}
      <EndCall />
      {customProps?.additionalLocalControls}
    </div>
  );
}

export default LocalControls;
